const en = require('./translations/locales/en.json')
const de = require('./translations/locales/de.json')
const nl = require('./translations/locales/nl.json')
const zh = require('./translations/locales/zh-Hans-CN.json')

// const pt = require('../locales/pt.json')

const supported = ['en', 'de', 'nl','zh']
let locale = 'en'

try {
    const {0: browserLang} = navigator.language.split('-')
    if (supported.includes(browserLang)) {
        locale = browserLang
    }
} catch (e) {
    console.log(e)
}
const endLanguage = ['en', 'de', 'zh']
let endLocale = ''
if (!endLanguage.includes(locale)) {
    endLocale = 'de'
} else {
    endLocale = locale
}

export const Remember = {
        lang: locale,
        requestLanguage: endLocale
    }

export default {
    // current locale
    locale: Remember.lang,

    // when translation is not available fallback to that locale
    fallbackLocale: 'de',

    // availabled locales for user selection
    availableLocales: [
        {
            code: 'de',
            flag: 'de',
            label: 'Deutsch',
            messages: de
        }, {
            code: 'en',
            flag: 'us',
            label: 'English',
            messages: en
        }, {
            code: 'nl',
            flag: 'nl',
            label: 'Nederlands',
            messages: nl
        },{
            code: 'zh',
            flag: 'zh',
            label: '简体中文',
            messages: zh
        },
    ]
}

