var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center",
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "text-caption font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.isPickUp ? _vm.$t('PickupBy') : _vm.$t('DeliveryTo')) + " ")]), _vm.haveAddressInfo ? _c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [[_vm._v(" " + _vm._s(_vm.addressInfo.firstName + ' ' + _vm.addressInfo.lastName)), _c('br')], !_vm.isPickUp ? _c('div', {
    staticClass: "text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.addressLine1 + ' ' + _vm.addressInfo.plz + ' ' + _vm.addressInfo.city) + " ")]) : _vm._e()], 2) : _c('div', {
    staticClass: "d-flex align-center text-body-1 font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "left": "",
      "color": "black"
    }
  }, [_vm._v("mdi-alert-circle")]), _vm._v(" " + _vm._s(_vm.isPickUp ? _vm.$t('MissingPickupInformation') : _vm.$t('MissingDeliveryInformation')) + " ")], 1)]), _c('v-spacer'), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }