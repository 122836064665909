<template>
  <div>
    <empty-page :title="'该网页不存在'" :tips="'或者输入正确的网站域名,或者检查您的网络设置!'" />
  </div>
</template>

<script>
import EmptyPage from "./widget/EmptyPage";
export default {
  name: "ErrorPage",
  components: {EmptyPage}
}
</script>

<style scoped>

</style>
