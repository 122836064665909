<template>
  <div>

    <div
        v-if="orderInfo!=null"
        class=" d-flex flex-column pa-4 py-6 fullScreenHeight full">
      <div class="text-h4 font-weight-black">{{ $t('OrderDetails') }}</div>
      <div class="text-body-1 mt-4 font-weight-black">
        <div
            style="width: 100%;"
            class="text-body-1 font-weight-black">{{ orderInfo.restaurantName }}
        </div>
        <span>{{ $t('OrderStatus') }}:</span>
        <span class="ml-2">{{ $t(orderStatus) }}</span>
      </div>
      <div class="text-body-1">
        {{ $t('OrderNumber') }}: <span class="ml-2">{{ orderInfo.id }}</span>
      </div>
      <div class="text-body-1 mt-8 my-2 font-weight-black">
        {{ $t('OrderedItems') }}
      </div>
      <v-card
          elevation="0"
          class="pa-2 px-3 mb-2 d-flex text-body-2"
          color="grey lighten-5"
          v-for="o in orders"
          :key="o.identity">
        <div class="font-weight-black">
          {{ o?.sumCount ?? o.count }}&times;
        </div>
        <div class="mx-2 flex-grow-1">
          <div>
            {{ o?.dishName ?? o?.name }}
          </div>
          <div>
            {{ o?.aName ?? o?.displayApply?.map(it => it.value)?.join(', ') ?? '' }}
          </div>
          <div
              v-if="o.note"
              class="text-body-2">
            #{{ o.note }}
          </div>

        </div>
        <div class="font-weight-black text-no-wrap">
          {{ o?.realPrice ?? o?.price | priceDisplay }}
        </div>

      </v-card>
      <v-divider class="my-4"></v-divider>
      <template>
        <div class="d-flex pa-2 py-0  text-body-2 font-weight-bold">
          {{ $t('TotalOf') }}
          <v-spacer></v-spacer>
          {{ orderTotalPrice | priceDisplay }}
        </div>
        <div
            v-if="deliveryCost>0"
            class="d-flex pa-2 py-0 text-body-2 font-weight-bold">
          {{ $t('DeliveryCost') }}:
          <v-spacer></v-spacer>
          {{ deliveryCost | priceDisplay }}
        </div>
        <div
            v-if="discount!=='0.00'"
            class="d-flex pa-2 py-0 text-body-2 font-weight-bold">
          {{ $t('discount') }}
          <v-spacer></v-spacer>
          {{ discount | priceDisplay }}
        </div>
      </template>

      <div class="d-flex pa-2 text-body-2 font-weight-black">
        {{ $t('TotalAmount') }}
        <v-spacer></v-spacer>
        {{ orderInfo.totalPrice | priceDisplay }}
      </div>

      <div class="pa-2 mt-8 text-body-1 font-weight-black">
        {{ $t('Recipient') }}
      </div>
      <div class="px-2 text-body-2">
        <div v-if="orderInfo.addressInfo">
          <div>{{ orderInfo.addressInfo.deliveryMethod }}</div>
          <div>{{ orderInfo.addressInfo.firstName }} {{ orderInfo.addressInfo.lastName }}</div>
          <div>
            {{ orderInfo.addressInfo.addressLine1 }}
            {{ orderInfo.addressInfo.city }}
            {{ orderInfo.addressInfo.plz }}
          </div>
          <div>{{ orderInfo.addressInfo.email }}</div>
          <div>{{ orderInfo.addressInfo.tel }}</div>
        </div>
        <div
            class="font-weight-black"
            v-if="orderInfo?.note">
          Note: {{ orderInfo?.note ?? '' }}
        </div>

      </div>
      <div class="pa-2 mt-2 text-body-1 font-weight-black d-flex align-center">
        {{ $t('PurchaseTimeStamp') }}
        <v-spacer></v-spacer>
      </div>
      <div class="px-2 pr-4 text-body-2">
        <div v-if="orderInfo.addressInfo">
          <div class="d-flex align-baseline">
            {{ orderInfo.addressInfo.date }}
            <v-spacer></v-spacer>
            <template
                v-if="orderInfo.addressInfo.oldTime&&
              orderInfo.addressInfo.oldTime!==orderInfo.addressInfo.time">
              <div class="text-body-2 mr-2 text-decoration-line-through">{{ orderInfo.addressInfo.oldTime }}</div>
            </template>
            {{ orderInfo.addressInfo.time }}
          </div>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-card
          @click="orderAgain"
          flat
          color="grey lighten-3"
          class="pa-4 font-weight-black my-2">
        <v-icon left>mdi-refresh</v-icon>
        {{ $t('ReOrder') }}
      </v-card>
      <v-card
          @click="$router.back()"
          flat
          color="grey lighten-3"
          class="pa-4 font-weight-black my-2">
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t('Back') }}
      </v-card>
    </div>


  </div>
</template>

<script>

import {getAllOrdersForUser} from "@/dataLayer/repository/userOrder";
import {sumBy} from "lodash-es";
import {Cart} from "@/dataLayer/service/CartService";
import router from "@/plugins/router";
import {getOrderStatusByCTSId} from "@/dataLayer/repository/currentOrderState";

export default {
  name: "OrderDetailPage",
  props: {id: {}},
  data: function () {
    return {
      orderInfo: null
    };
  },
  computed: {
    orderStatus() {
      return getOrderStatusByCTSId(this.orderInfo?.consumeTypeStatusId ?? 1)
    },
    orderTotalPrice() {
      return sumBy(this.orders, (it) => (it?.count ?? it.sumCount) * (it?.realPrice ?? it.price))
    },
    deliveryCost() {
      return this.orderInfo?.deliveryCost ?? 0
    },
    discount() {
      return (this.orderInfo.totalPrice - this.orderTotalPrice - this.deliveryCost).toFixed(2)
    },
    orders() {
      return this.orderInfo?.order ?? []
    }
  },
  methods: {
    orderAgain() {
      Cart.clear()
      Cart.loadTTDishList(this.orders.filter(it => it.code !== 'lk'))
      router.push('/menu')
    }
  },
  async mounted() {
    this.orderInfo = (await getAllOrdersForUser()).find(it => it.id === parseInt(this.id))
    console.log(this.orderInfo, 'order info')
  }
}
</script>

<style scoped>

</style>
