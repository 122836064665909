var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-center align-center",
    staticStyle: {
      "height": "calc(100%)"
    }
  }, [_c('lottie-animation', {
    ref: "animation",
    staticStyle: {
      "max-width": "200px"
    },
    attrs: {
      "autoPlay": false,
      "animationData": require('@/assets/error.json')
    }
  }), _c('v-fade-transition', [_vm.show ? _c('div', {
    staticClass: "pa-4 mt-12 d-flex flex-column align-center text-center"
  }, [_c('div', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('Ops')))]), _c('div', {
    staticClass: "text-body-1 mt-4"
  }, [_vm._v(_vm._s(_vm.$t('ThisFunctionIsNotAvailable')))]), _c('div', {
    staticClass: "text-body-1 mt-2"
  }, [_vm._v(_vm._s(_vm.$t('MadeByInnerken')))]), _c('v-btn', {
    staticClass: "primary mt-4 elevation-0",
    staticStyle: {
      "border-radius": "36px"
    },
    attrs: {
      "width": "100%"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(_vm._s(_vm.$t('BackToAvailablePages')))])], 1) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }