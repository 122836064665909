<template>
  <div id="app">
    <v-app>
      <v-main app>
        <router-view></router-view>
      </v-main>
      <v-bottom-navigation
          v-if="showNavigation"
          :value="activeRoute"
          app
          :dark="shouldUseDark"
          :class="shouldUseDark?'elevation-0':''"
          :color="shouldUseDark?'white':'black'"
          grow
          height="64">
        <v-btn
            @click="goto(b.path)"
            v-for="b in navigationButtons"
            :key="b.path"
            :value="b.path">
          <span
              :class="activeRoute===b.path?'font-weight-black':''"
              class="mt-n1 text-capitalize font-weight-medium"
          >{{ b.title }}</span>
          <v-card
              elevation="0"
              style="border-radius: 12px"
              :color="activeRoute===b.path?'rgba(0,0,0,0.07)':'transparent'"
              class="mb-1 pa-2">
            <v-icon>mdi-{{ b.icon }}</v-icon>
          </v-card>
        </v-btn>
      </v-bottom-navigation>
    </v-app>
  </div>
</template>
<script>

import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import i18n from "@/i18n"

export default {
  name: 'App',
  data() {
    return {
      GlobalConfig,
      navigationButtons: [
        {path: '/home', icon: 'home', title: i18n.t('homePage')},
        {path: '/menu', icon: 'food-fork-drink', title: i18n.t('Menu')},
        {path: '/orders', icon: 'history', title: i18n.t('History')},
        {path: '/me', icon: 'account', title: i18n.t('PersonalInformation')},
      ]
    }
  },
  computed: {
    shouldUseDark() {
      return this.activeRoute === '/me' || this.activeRoute === '/home'
    },
    showNavigation() {
      return this.navigationButtons.map(it => it.path).includes(this.$route.path)
    },
    activeRoute() {
      return this.$route.path
    },
  },
  methods: {
    goto(path) {
      if (this.$route.path !== path) {
        this.$router.push(path)
      }

    },
  }

}
</script>

<style>
.fullScreenHeight {
  min-height: calc(100vh - 64px);
  min-height: calc(100svh - 64px);
}

.fullScreenHeight.full {
  min-height: calc(100vh);
  min-height: calc(100svh);
}
body {
  user-select: none;
}

.dishImage {
  border-radius: 16px;
  width: 80px;
  height: 80px;
  margin-left: 16px;
}

.dishLabel {
  flex-grow: 0;
}

.dishPrice {
  padding-left: 4px;
  font-size: 0.9rem;
  color: #009de0;
}

.fakeButton:hover {
  cursor: pointer;
}

.dishDesc {
  padding-left: 5px;
  color: rgba(32, 33, 37, 0.64);
  position: relative;
  font-size: .75rem;
  line-height: 1.4;
  max-height: 4.2em;
  margin-bottom: 0.375rem;
  letter-spacing: -.15px;
  white-space: pre-line;
  overflow: hidden;
}

.dishDescDetail {
  padding-left: 5px;
  color: rgba(32, 33, 37, 0.64);
  position: relative;
  font-size: .75rem;
  line-height: 1.4;
  margin-bottom: 0.375rem;
  letter-spacing: -.15px;
  white-space: pre-line;
}


.dishName {
  padding: 0;
  font-size: 0.98rem;
  line-height: 1.4;
  max-height: 2.8em;
  font-weight: 600;
  margin-bottom: 0.375rem;
  white-space: pre-wrap;
  overflow: hidden;
  max-width: calc(100vw - 32px);
  text-overflow: ellipsis;
}

.enhanceDisplay {
  position: absolute;
  background: #009de0;
  top: 1.5rem;
  left: 0;
  height: 100px;
  border-radius: 4px;
  width: 4px;
  transform-origin: 0%;
  will-change: transform;
}

.dishItem {
  min-width: 300px;
  padding: 1.5rem 16px;
  justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 2px;
  display: flex;
  position: relative;
  transition: 0.3s;
}

.checkOutPageButton {
  position: fixed;
  bottom: 16px;
  left: 0;
  width: 100vw;
  height: 120px;
  z-index: 100;
  max-width: 1150px;
}

.dishItem:hover {
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #bebebe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.addMoreItem {
  min-width: 300px;
  padding: 1.5rem 16px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: 0.3s;
}

.addMoreItem:hover {
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #bebebe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.categoryDesc {
  font-size: 0.9rem;
  color: rgba(32, 33, 37, 0.64);
  font-weight: 400;
  margin-top: 16px;
  white-space: pre-wrap;
}

.itemContainer {
  width: 100%;
  overflow: hidden;
}

.categoryContainer {
  margin: 1.5rem 16px
}

.infoButton {
  background: #ebf7fd !important;
  color: #009de0 !important;
  text-transform: capitalize;
}

.infoButton:hover {
  background: #ccebf9;
}

.listItem {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0;
}


.contentPadding {
  padding: 24px 16px;
}

.expandContent:hover {
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: #CCCCCC;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #999;
  -webkit-border-radius: 4px;
  outline: 2px solid #fff;
  outline-offset: -2px;
  border: 2px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  height: 50px;
  background-color: #9f9f9f;
  -webkit-border-radius: 4px;
}


.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
}
</style>
<style lang="scss">
#app {
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
