var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "dark": ""
    },
    model: {
      value: _vm.realValue,
      callback: function ($$v) {
        _vm.realValue = $$v;
      },
      expression: "realValue"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-6",
    staticStyle: {
      "width": "100vw"
    }
  }, [_c('div', {
    staticClass: "d-flex text-body-1 align-center font-weight-black"
  }, [_vm.isPickUp ? [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.isPickUp && !_vm.noAddressNow ? 'mdi-account' : 'mdi-account-alert') + " ")]), _vm._v(" " + _vm._s(_vm.isPickUp && !_vm.noAddressNow ? _vm.$t('ContactData') : _vm.$t('MissingContactData')) + " ")] : _vm._e(), !_vm.isPickUp ? [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(!_vm.isPickUp && !_vm.noAddressNow ? 'mdi-map' : 'mdi-map-marker-alert') + " ")]), _vm._v(" " + _vm._s(!_vm.isPickUp && !_vm.noAddressNow ? _vm.$t('AddressData') : _vm.$t('MissingAddressData')) + " ")] : _vm._e()], 2), _c('div', [_vm.noAddressNow ? _c('div', [_c('empty-page', {
    attrs: {
      "title": _vm.noAddressNow && !_vm.isPickUp ? _vm.$t('AddMissingAddressData') : _vm.$t('AddMissingContactData'),
      "tips": _vm.$t('AddMissingDataHint'),
      "height": "300px"
    }
  })], 1) : _c('div', {
    staticClass: "my-8"
  }, [_vm._l(_vm.currentAddressList, function (a) {
    return [_c('v-card', {
      key: a.firstName + a.lastName + a.addressLine1 + a.city,
      staticClass: "py-2 px-1 my-2",
      attrs: {
        "width": "100%",
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          return _vm.pop(a);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('div', {
      staticClass: "text-h5 font-weight-black text-capitalize text-no-wrap text-truncate"
    }, [_vm._v(" " + _vm._s(a.firstName) + " " + _vm._s(a.lastName) + " ")]), a.isDefault ? _c('div', {
      staticClass: "text-caption ml-2"
    }, [_c('v-icon', [_vm._v("mdi-home-circle")])], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
      staticClass: "ml-2",
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.deleteAddress(a);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1), _c('v-btn', {
      staticClass: "ml-2",
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectDetail(a);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-square-edit-outline")])], 1)], 1), _c('div', [_c('div', {
      staticClass: "d-flex align-center text-caption text-truncate"
    }, [_vm.isSelectedAddress(a) ? _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "amber"
      }
    }, [_vm._v("mdi-checkbox-marked-circle ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.isPickUp ? a.tel : a.addressLine1 + ', ' + a.plz + ' ' + a.city) + " ")], 1), !_vm.canDelivery(a) ? _c('div', {
      staticClass: "text-body-2 font-weight-black"
    }, [_vm._v(" " + _vm._s(_vm.$t('AddressIsNotInDeliveryRange')) + " "), _c('v-icon', {
      attrs: {
        "color": "error lighten-2",
        "small": ""
      }
    }, [_vm._v("mdi-close-circle ")])], 1) : _vm._e()])])];
  })], 2), _c('v-card', {
    staticClass: "pa-4 text-center text-body-1 font-weight-black",
    attrs: {
      "elevation": "0",
      "color": "white black--text"
    },
    on: {
      "click": _vm.addAddress
    }
  }, [_c('v-icon', {
    staticClass: "mr-2 ml-n1",
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" " + _vm._s(_vm.isPickUp ? _vm.$t('AddContactData') : _vm.$t('AddAddressData')) + " ")], 1)], 1), _c('v-bottom-sheet', {
    model: {
      value: _vm.showDeleteConfirmDialog,
      callback: function ($$v) {
        _vm.showDeleteConfirmDialog = $$v;
      },
      expression: "showDeleteConfirmDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "dark": "",
      "color": "black"
    }
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('Attention')))]), _c('div', {
    staticClass: "text-body-1 my-4"
  }, [_vm._v(_vm._s(_vm.$t('UnrecoverableDelete')))]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "text-body-2 text-capitalize font-weight-black",
    attrs: {
      "elevation": "0",
      "large": "",
      "width": "50%",
      "color": "black white--text",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showDeleteConfirmDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Back')) + " ")]), _c('v-btn', {
    staticClass: "text-body-2 text-capitalize font-weight-black",
    attrs: {
      "large": "",
      "elevation": "0",
      "width": "50%",
      "color": "white black--text",
      "tile": ""
    },
    on: {
      "click": _vm.confirmDelete
    }
  }, [_vm._v(_vm._s(_vm.$t('Delete')) + " ")])], 1)])], 1), _c('add-new-address-dialog', {
    attrs: {
      "old-id": _vm.oldId,
      "type": _vm.deliveryMethod
    },
    on: {
      "address-save": _vm.saveAndReload
    },
    model: {
      value: _vm.showAddNewAddressDialog,
      callback: function ($$v) {
        _vm.showAddNewAddressDialog = $$v;
      },
      expression: "showAddNewAddressDialog"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }