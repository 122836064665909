import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/pages/menuPage/MainPage'
import CheckoutPage from '@/pages/menuPage/CheckoutPage'
import OrderComplete from "@/pages/menuPage/OrderComplete";
import LoadingPage from "@/pages/LoadingPage";
import NotPayPage from "@/pages/menuPage/NotPayPage";
import MyPage from "@/pages/MyPage";
import OrdersPage from "@/pages/orderPage/OrdersPage";
import LoginPage from "@/pages/LoginPage";
import OrderDetailPage from "@/pages/orderPage/OrderDetailPage";
import HomePage from "@/pages/HomePage";
import ErrorPage from "../../pages/ErrorPage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'loading',
        component: LoadingPage
    },
    {
        path: '/menu',
        name: 'Menu',
        component: MainPage
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: CheckoutPage
    },
    {
        path: '/complete',
        name: 'Complete',
        component: OrderComplete
    },
    {
        path: '/notPaid',
        name: 'notPaid',
        component: NotPayPage
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/me',
        name: 'me',
        component: MyPage
    },
    {
        path: '/home',
        name: 'home',
        component: HomePage
    },
    {
        path: '/orders',
        name: 'orders',
        component: OrdersPage
    },
    {
        path: '/order/:id',
        name: 'order',
        props: true,
        component: OrderDetailPage
    },
    {
        path: '/Error',
        name: 'error',
        component: ErrorPage
    },
]

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
})

export default router
