import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import i18n from './i18n'
import {loadConfig} from '@/dataLayer/repository/GlobalSettingManager'
import dayjs from "dayjs";
import weekday from 'dayjs/plugin/weekday'
import relativeTime from 'dayjs/plugin/relativeTime'

const duration = require('dayjs/plugin/duration')
const isoWeek = require('dayjs/plugin/isoWeek')
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
dayjs.extend(isoWeek)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(weekday)

Vue.config.productionTip = false
Vue.filter('timeToNow', function (time) {
    return dayjs(time).fromNow()
})
Vue.filter('priceDisplay',
    function (price) {
        return parseFloat(price).toFixed(2).replace('.', ',') + ' €'
    }
)

async function init() {
    await loadConfig()
    new Vue({
        vuetify,
        router,
        i18n,
        render: h => h(App)
    }).$mount('#app')
}

init()
