<template>
  <v-card
      flat
      tile
      min-height="calc(100vh - 64px)"
      color="black white--text"
      class="pa-4 d-flex flex-column">
    <div class="text-body-2 mt-4 font-weight-black">
      {{ $t('LoggedInAs') }}
    </div>
    <div class="text-h2 mt-4 font-weight-black">
      {{ userInfo?.displayName ?? $t('NoName') }}
    </div>
    <div class="text-body-2 mt-12 font-weight-black">
      {{ $t('MemberSince') }}
    </div>
    <div class="text-h2 mt-4 font-weight-black">
      {{ createTime }}
    </div>
    <v-spacer></v-spacer>
    <div class="mt-12">
      <v-card
          flat
          @click="showNameDialog=true"
          class="pa-4 mb-2 text-body-1 font-weight-black d-flex
                 align-center black--text"
          color="white"
      >
        {{ $t('UserInfo') }}
        <v-spacer></v-spacer>
        <v-icon color="black">mdi-account</v-icon>
      </v-card>
      <v-card
          flat
          @click="showAddressManager(DeliveryMethod.pickup)"
          class="pa-4 mb-2 text-body-1 font-weight-black d-flex
           align-center black--text"
          color="white"
      >
        {{ $t('ContactData') }}
        <v-spacer></v-spacer>
        <v-icon color="black">mdi-text-account</v-icon>
      </v-card>
      <v-card
          flat
          @click="showAddressManager(DeliveryMethod.delivery)"
          class="pa-4 mb-2 text-body-1 font-weight-black d-flex
           align-center black--text"
          color="white"
      >
        {{ $t('AddressData') }}
        <v-spacer></v-spacer>
        <v-icon color="black">mdi-map-marker</v-icon>
      </v-card>
      <v-card
          flat
          @click="logout"
          class="pa-4 mb-2 text-body-1 font-weight-black d-flex
           align-center black--text"
          color="white"
      >
        {{ $t('Logout') }}
        <v-spacer></v-spacer>
        <v-icon color="black">mdi-logout</v-icon>
      </v-card>
    </div>
    <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(2,minmax(150px,auto))">
      <div>
        <div class="text-caption  font-weight-black">
          <v-icon
              small
              color="#D0EF27">mdi-store
          </v-icon>
          <v-icon
              small
              color="white lighten-4">mdi-emoticon
          </v-icon>
          <v-icon
              small
              color="pink lighten-4">mdi-map-marker
          </v-icon>
        </div>
        <div class="text-body-1 mt-0 font-weight-black d-flex">
          <div class="text-no-wrap text-truncate">
            {{ restaurantInfo?.name }}
          </div>
        </div>
      </div>

      <div
      >
        <div class="text-caption  font-weight-black">
          {{ $t('AadenPosShops') }}
        </div>
        <div class="text-body-1 mt-0 font-weight-black">
          <v-card
              width="fit-content"
              class="px-1 text-no-wrap"
              tile
              flat
              color="white">Aaden POS
          </v-card>
        </div>
      </div>

    </div>

    <div style="height: 12px"></div>
    <address-selector
        :init-delivery-method="currentDeliveryMethod"
        v-model="showAddressSelector"
    />
    <v-bottom-sheet v-model="showNameDialog">
      <v-card
          width="100%"
          class="pa-4 pt-8"
          tile
          dark>
        <div class="text-h4 font-weight-black">
          {{ $t('EnterUsername') }}
        </div>
        <v-text-field
            solo-inverted
            color="white"
            class="mt-8"
            v-model="nameInput"></v-text-field>
        <v-btn
            @click="updateUserName"
            large
            class="text-body-1 font-weight-black text-capitalize"
            color="white black--text"
            block>
          {{ $t('Save') }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </v-card>

</template>

<script>
import {FireBaseAuth, logout, updateUserProfile} from "@/plugins/fire-base"
import router from "@/plugins/router"
import {getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo"
import AddressSelector from "@/pages/widget/adress/AddressSelector"
import dayjs from "dayjs"
import {DeliveryMethod} from "@/dataLayer/service/OrderService"
import {OrderInfo} from "@/dataLayer/repository/currentOrderState"

export default {
  name: "MyPage",
  computed: {
    DeliveryMethod() {
      return DeliveryMethod
    }
  },
  components: {AddressSelector},
  data: function () {
    return {
      restaurantInfo: null,
      showAddressSelector: false,
      currentDeliveryMethod: DeliveryMethod.delivery,
      showNameDialog: false,
      nameInput: '',
      userInfo: null,
      createTime: '',
    };
  },
  async mounted() {
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.loadUserInfo()
  },
  methods: {
    loadUserInfo() {
      this.userInfo = FireBaseAuth.currentUser
      this.createTime =
          dayjs(parseInt(this.userInfo?.reloadUserInfo?.createdAt)).format('DD/MM/YYYY')
      this.nameInput = this.userInfo?.displayName ?? ''
    },
    async updateUserName() {
      await updateUserProfile(this.nameInput)
      await this.loadUserInfo()
      this.showNameDialog = false
    },
    showAddressManager(deliveryMethod) {
      this.currentDeliveryMethod = deliveryMethod
      this.showAddressSelector = true
    },
    logout() {
      OrderInfo.deliveryRule = null
      OrderInfo.deliveryMethod = null
      OrderInfo.address = {}
      logout()
      router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
