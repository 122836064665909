var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('v-app', [_c('v-main', {
    attrs: {
      "app": ""
    }
  }, [_c('router-view')], 1), _vm.showNavigation ? _c('v-bottom-navigation', {
    class: _vm.shouldUseDark ? 'elevation-0' : '',
    attrs: {
      "value": _vm.activeRoute,
      "app": "",
      "dark": _vm.shouldUseDark,
      "color": _vm.shouldUseDark ? 'white' : 'black',
      "grow": "",
      "height": "64"
    }
  }, _vm._l(_vm.navigationButtons, function (b) {
    return _c('v-btn', {
      key: b.path,
      attrs: {
        "value": b.path
      },
      on: {
        "click": function ($event) {
          return _vm.goto(b.path);
        }
      }
    }, [_c('span', {
      staticClass: "mt-n1 text-capitalize font-weight-medium",
      class: _vm.activeRoute === b.path ? 'font-weight-black' : ''
    }, [_vm._v(_vm._s(b.title))]), _c('v-card', {
      staticClass: "mb-1 pa-2",
      staticStyle: {
        "border-radius": "12px"
      },
      attrs: {
        "elevation": "0",
        "color": _vm.activeRoute === b.path ? 'rgba(0,0,0,0.07)' : 'transparent'
      }
    }, [_c('v-icon', [_vm._v("mdi-" + _vm._s(b.icon))])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }