<template>
  <v-card
      v-if="dishCount>0"
      :disabled="disable"
      @click="$emit('click')"
      dark
      :color="disable?'grey darken-4':'grey darken-4'"
      class="pa-4 font-weight-black"
      style="position: fixed;
          bottom: 76px;
          left: 16px;
          right: 16px;
          box-shadow: rgba(0,0,0,.04) 0 -4px 12px;
           z-index: 100 !important;
"
  >
    <div
        class="d-flex align-center"
    >
      <v-badge
          class="mr-4"
          :content="dishCount"
          overlap
          color="white black--text darken-4"
          offset-x="12">
        <v-icon>mdi-shopping-outline</v-icon>
      </v-badge>
      <slot>
        {{ $t('Basket') }}
      </slot>
      <v-spacer></v-spacer>
      <template v-if="restaurantIsOpen">
        <div class="text-body-2 text-end font-weight-black">
          {{ totalPrice | priceDisplay }}
          <div
              v-if="!isPickUp"
              class="text-caption mt-n1"
              style="font-size: 10px !important;">
            <template v-if="differentPrice>0">
              {{ $t('MinimumOrderValue') }} {{ startPrice|priceDisplay }}
            </template>
            <template v-else-if="differentFreePrice>0">
              {{ differentFreePrice |priceDisplay }}{{ $t('TillYouReachedDeliveryFree') }}
            </template>
            <template v-else-if="differentFreePrice<0">
              🎉 {{ $t('FreeDelivery') }}
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-body-1 font-weight-black">
          {{ $t('ShopIsClosed') }}
        </div>
      </template>

    </div>
  </v-card>
</template>

<script>
export default {
  name: "CartButton",
  props: {
    dishCount: {},
    totalPrice: {},
    isPickUp: {},
    startPrice: {},
    freePrice: {},
    restaurantIsOpen: {
      default: true
    },
    disable: {}
  },
  computed: {
    differentFreePrice() {
      return this.freePrice - this.totalPrice
    },
    differentPrice() {
      return this.startPrice - this.totalPrice
    }
  }
}
</script>

<style scoped>

</style>
