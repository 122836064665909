var render = function render(){
  var _vm$userInfo$displayN, _vm$userInfo, _vm$restaurantInfo;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-4 d-flex flex-column",
    attrs: {
      "flat": "",
      "tile": "",
      "min-height": "calc(100vh - 64px)",
      "color": "black white--text"
    }
  }, [_c('div', {
    staticClass: "text-body-2 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('LoggedInAs')) + " ")]), _c('div', {
    staticClass: "text-h2 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s((_vm$userInfo$displayN = (_vm$userInfo = _vm.userInfo) === null || _vm$userInfo === void 0 ? void 0 : _vm$userInfo.displayName) !== null && _vm$userInfo$displayN !== void 0 ? _vm$userInfo$displayN : _vm.$t('NoName')) + " ")]), _c('div', {
    staticClass: "text-body-2 mt-12 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('MemberSince')) + " ")]), _c('div', {
    staticClass: "text-h2 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.createTime) + " ")]), _c('v-spacer'), _c('div', {
    staticClass: "mt-12"
  }, [_c('v-card', {
    staticClass: "pa-4 mb-2 text-body-1 font-weight-black d-flex align-center black--text",
    attrs: {
      "flat": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        _vm.showNameDialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('UserInfo')) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-account")])], 1), _c('v-card', {
    staticClass: "pa-4 mb-2 text-body-1 font-weight-black d-flex align-center black--text",
    attrs: {
      "flat": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.showAddressManager(_vm.DeliveryMethod.pickup);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ContactData')) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-text-account")])], 1), _c('v-card', {
    staticClass: "pa-4 mb-2 text-body-1 font-weight-black d-flex align-center black--text",
    attrs: {
      "flat": "",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.showAddressManager(_vm.DeliveryMethod.delivery);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('AddressData')) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-map-marker")])], 1), _c('v-card', {
    staticClass: "pa-4 mb-2 text-body-1 font-weight-black d-flex align-center black--text",
    attrs: {
      "flat": "",
      "color": "white"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Logout')) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-logout")])], 1)], 1), _c('div', {
    staticClass: "mt-4",
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "repeat(2,minmax(150px,auto))"
    }
  }, [_c('div', [_c('div', {
    staticClass: "text-caption font-weight-black"
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "#D0EF27"
    }
  }, [_vm._v("mdi-store ")]), _c('v-icon', {
    attrs: {
      "small": "",
      "color": "white lighten-4"
    }
  }, [_vm._v("mdi-emoticon ")]), _c('v-icon', {
    attrs: {
      "small": "",
      "color": "pink lighten-4"
    }
  }, [_vm._v("mdi-map-marker ")])], 1), _c('div', {
    staticClass: "text-body-1 mt-0 font-weight-black d-flex"
  }, [_c('div', {
    staticClass: "text-no-wrap text-truncate"
  }, [_vm._v(" " + _vm._s((_vm$restaurantInfo = _vm.restaurantInfo) === null || _vm$restaurantInfo === void 0 ? void 0 : _vm$restaurantInfo.name) + " ")])])]), _c('div', [_c('div', {
    staticClass: "text-caption font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('AadenPosShops')) + " ")]), _c('div', {
    staticClass: "text-body-1 mt-0 font-weight-black"
  }, [_c('v-card', {
    staticClass: "px-1 text-no-wrap",
    attrs: {
      "width": "fit-content",
      "tile": "",
      "flat": "",
      "color": "white"
    }
  }, [_vm._v("Aaden POS ")])], 1)])]), _c('div', {
    staticStyle: {
      "height": "12px"
    }
  }), _c('address-selector', {
    attrs: {
      "init-delivery-method": _vm.currentDeliveryMethod
    },
    model: {
      value: _vm.showAddressSelector,
      callback: function ($$v) {
        _vm.showAddressSelector = $$v;
      },
      expression: "showAddressSelector"
    }
  }), _c('v-bottom-sheet', {
    model: {
      value: _vm.showNameDialog,
      callback: function ($$v) {
        _vm.showNameDialog = $$v;
      },
      expression: "showNameDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4 pt-8",
    attrs: {
      "width": "100%",
      "tile": "",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('EnterUsername')) + " ")]), _c('v-text-field', {
    staticClass: "mt-8",
    attrs: {
      "solo-inverted": "",
      "color": "white"
    },
    model: {
      value: _vm.nameInput,
      callback: function ($$v) {
        _vm.nameInput = $$v;
      },
      expression: "nameInput"
    }
  }), _c('v-btn', {
    staticClass: "text-body-1 font-weight-black text-capitalize",
    attrs: {
      "large": "",
      "color": "white black--text",
      "block": ""
    },
    on: {
      "click": _vm.updateUserName
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Save')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }