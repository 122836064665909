<template>
  <div
      class="d-flex align-center justify-center flex-column"
      :style="{minHeight:height}">
    <v-icon
        size="64"
        class="mt-4">{{ icon }}
    </v-icon>
    <div class="mt-4 font-weight-black text-h6">
      {{ title }}
    </div>
    <div class="mt-0 text-body-1">
      {{ tips }}
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n"
export default {
  name: "EmptyPage",
  props: {
    height: {
      default: 'calc( 100vh - 128px)'
    },
    icon: {
      default: 'mdi-store-search'
    },
    title: {
      default: i18n.t('NothingToFindHere')
    },
    tips: {
      default: i18n.t('NothingToFindHereHint')
    }

  }
}
</script>

<style scoped>

</style>
