import {getDocs} from "firebase/firestore";

export function rulePassFor(rules, string) {
    return rules.every(it => it(string) && typeof it(string) !== 'string')
}


export async function executeQuery(query) {
    return getDocContentWithId(await getDocs(query));
}


function getDocContentWithId(docs) {
    return docs.docs.map(docContent);
}

export function docContent(doc) {
    return {
        id: doc.id,
        ...doc.data(),
    };
}



