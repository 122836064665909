<template>
  <div
      class="d-flex flex-column justify-center fullScreenHeight"
  >
    <v-fade-transition>
      <div
          class="pa-4 text-center"
          v-if="show">
        <v-icon
            center
            size="100">
          mdi-check-all
        </v-icon>
        <div class="text-h4 font-weight-black">{{ $t('OrderSubmitted') }}</div>
        <div class="text-body-2 mt-4">{{
            $t('YourOrderBeenReceived')
          }}
        </div>
        <v-btn
            block
            x-large
            elevation="0"
            height="64"
            class="font-weight-black text-body-1"
            color="grey lighten-4"
            style="position: fixed;bottom: 0;left: 0"
            @click="$router.push('/')"> {{ $t('Finish') }}
        </v-btn>
      </div>
    </v-fade-transition>

  </div>
</template>

<script>


export default {
  name: "OrderComplete",
  data: function () {
    return {show: false};
  },
  mounted() {
    setTimeout(() => {
      this.show = true
    }, 1000)
  }
}
</script>

<style scoped>

</style>
