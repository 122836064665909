var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-center justify-center flex-column",
    style: {
      minHeight: _vm.height
    }
  }, [_c('v-icon', {
    staticClass: "mt-4",
    attrs: {
      "size": "64"
    }
  }, [_vm._v(_vm._s(_vm.icon) + " ")]), _c('div', {
    staticClass: "mt-4 font-weight-black text-h6"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "mt-0 text-body-1"
  }, [_vm._v(" " + _vm._s(_vm.tips) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }