var render = function render(){
  var _vm$orderInfo, _vm$orderInfo$note, _vm$orderInfo2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.orderInfo != null ? _c('div', {
    staticClass: "d-flex flex-column pa-4 py-6 fullScreenHeight full"
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('OrderDetails')))]), _c('div', {
    staticClass: "text-body-1 mt-4 font-weight-black"
  }, [_c('div', {
    staticClass: "text-body-1 font-weight-black",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(_vm._s(_vm.orderInfo.restaurantName) + " ")]), _c('span', [_vm._v(_vm._s(_vm.$t('OrderStatus')) + ":")]), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.$t(_vm.orderStatus)))])]), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderNumber')) + ": "), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.orderInfo.id))])]), _c('div', {
    staticClass: "text-body-1 mt-8 my-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderedItems')) + " ")]), _vm._l(_vm.orders, function (o) {
    var _o$sumCount, _o$dishName, _ref, _o$aName, _o$displayApply, _o$displayApply$map, _o$realPrice;
    return _c('v-card', {
      key: o.identity,
      staticClass: "pa-2 px-3 mb-2 d-flex text-body-2",
      attrs: {
        "elevation": "0",
        "color": "grey lighten-5"
      }
    }, [_c('div', {
      staticClass: "font-weight-black"
    }, [_vm._v(" " + _vm._s((_o$sumCount = o === null || o === void 0 ? void 0 : o.sumCount) !== null && _o$sumCount !== void 0 ? _o$sumCount : o.count) + "× ")]), _c('div', {
      staticClass: "mx-2 flex-grow-1"
    }, [_c('div', [_vm._v(" " + _vm._s((_o$dishName = o === null || o === void 0 ? void 0 : o.dishName) !== null && _o$dishName !== void 0 ? _o$dishName : o === null || o === void 0 ? void 0 : o.name) + " ")]), _c('div', [_vm._v(" " + _vm._s((_ref = (_o$aName = o === null || o === void 0 ? void 0 : o.aName) !== null && _o$aName !== void 0 ? _o$aName : o === null || o === void 0 ? void 0 : (_o$displayApply = o.displayApply) === null || _o$displayApply === void 0 ? void 0 : (_o$displayApply$map = _o$displayApply.map(function (it) {
      return it.value;
    })) === null || _o$displayApply$map === void 0 ? void 0 : _o$displayApply$map.join(', ')) !== null && _ref !== void 0 ? _ref : '') + " ")]), o.note ? _c('div', {
      staticClass: "text-body-2"
    }, [_vm._v(" #" + _vm._s(o.note) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "font-weight-black text-no-wrap"
    }, [_vm._v(" " + _vm._s(_vm._f("priceDisplay")((_o$realPrice = o === null || o === void 0 ? void 0 : o.realPrice) !== null && _o$realPrice !== void 0 ? _o$realPrice : o === null || o === void 0 ? void 0 : o.price)) + " ")])]);
  }), _c('v-divider', {
    staticClass: "my-4"
  }), [_c('div', {
    staticClass: "d-flex pa-2 py-0 text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('TotalOf')) + " "), _c('v-spacer'), _vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.orderTotalPrice)) + " ")], 1), _vm.deliveryCost > 0 ? _c('div', {
    staticClass: "d-flex pa-2 py-0 text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('DeliveryCost')) + ": "), _c('v-spacer'), _vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.deliveryCost)) + " ")], 1) : _vm._e(), _vm.discount !== '0.00' ? _c('div', {
    staticClass: "d-flex pa-2 py-0 text-body-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('discount')) + " "), _c('v-spacer'), _vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.discount)) + " ")], 1) : _vm._e()], _c('div', {
    staticClass: "d-flex pa-2 text-body-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmount')) + " "), _c('v-spacer'), _vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.orderInfo.totalPrice)) + " ")], 1), _c('div', {
    staticClass: "pa-2 mt-8 text-body-1 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('Recipient')) + " ")]), _c('div', {
    staticClass: "px-2 text-body-2"
  }, [_vm.orderInfo.addressInfo ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.orderInfo.addressInfo.deliveryMethod))]), _c('div', [_vm._v(_vm._s(_vm.orderInfo.addressInfo.firstName) + " " + _vm._s(_vm.orderInfo.addressInfo.lastName))]), _c('div', [_vm._v(" " + _vm._s(_vm.orderInfo.addressInfo.addressLine1) + " " + _vm._s(_vm.orderInfo.addressInfo.city) + " " + _vm._s(_vm.orderInfo.addressInfo.plz) + " ")]), _c('div', [_vm._v(_vm._s(_vm.orderInfo.addressInfo.email))]), _c('div', [_vm._v(_vm._s(_vm.orderInfo.addressInfo.tel))])]) : _vm._e(), (_vm$orderInfo = _vm.orderInfo) !== null && _vm$orderInfo !== void 0 && _vm$orderInfo.note ? _c('div', {
    staticClass: "font-weight-black"
  }, [_vm._v(" Note: " + _vm._s((_vm$orderInfo$note = (_vm$orderInfo2 = _vm.orderInfo) === null || _vm$orderInfo2 === void 0 ? void 0 : _vm$orderInfo2.note) !== null && _vm$orderInfo$note !== void 0 ? _vm$orderInfo$note : '') + " ")]) : _vm._e()]), _c('div', {
    staticClass: "pa-2 mt-2 text-body-1 font-weight-black d-flex align-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('PurchaseTimeStamp')) + " "), _c('v-spacer')], 1), _c('div', {
    staticClass: "px-2 pr-4 text-body-2"
  }, [_vm.orderInfo.addressInfo ? _c('div', [_c('div', {
    staticClass: "d-flex align-baseline"
  }, [_vm._v(" " + _vm._s(_vm.orderInfo.addressInfo.date) + " "), _c('v-spacer'), _vm.orderInfo.addressInfo.oldTime && _vm.orderInfo.addressInfo.oldTime !== _vm.orderInfo.addressInfo.time ? [_c('div', {
    staticClass: "text-body-2 mr-2 text-decoration-line-through"
  }, [_vm._v(_vm._s(_vm.orderInfo.addressInfo.oldTime))])] : _vm._e(), _vm._v(" " + _vm._s(_vm.orderInfo.addressInfo.time) + " ")], 2)]) : _vm._e()]), _c('v-spacer'), _c('v-card', {
    staticClass: "pa-4 font-weight-black my-2",
    attrs: {
      "flat": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": _vm.orderAgain
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-refresh")]), _vm._v(" " + _vm._s(_vm.$t('ReOrder')) + " ")], 1), _c('v-card', {
    staticClass: "pa-4 font-weight-black my-2",
    attrs: {
      "flat": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$t('Back')) + " ")], 1)], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }