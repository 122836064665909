var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "pa-4 pt-8 flex-shrink-0"
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderDetailsAndHistory')) + " ")]), _c('div', {
    staticClass: "text-h4 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('OrderHistory')) + " ")]), _vm.loading ? [_c('div', {
    staticClass: "d-flex align-center justify-center",
    staticStyle: {
      "min-height": "400px"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": ""
    }
  })], 1)] : [_vm.orders.length === 0 ? _c('empty-page') : _c('div', {
    staticClass: "mt-8"
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black mb-4"
  }, [_vm._v(_vm._s(_vm.$t('History')))]), _vm._l(_vm.orders, function (o) {
    var _o$consumeTypeStatusI, _o$addressInfo, _o$addressInfo2;
    return _c('v-card', {
      key: o.id,
      staticClass: "mb-2 pa-4 px-6",
      attrs: {
        "outlined": "",
        "rounded": "lg"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push('/order/' + o.id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('div', {
      staticClass: "text-body-1 font-weight-black"
    }, [_vm._v(_vm._s(o.restaurantName))]), _c('v-spacer'), _c('div', {
      staticClass: "text-body-2 font-weight-black text-capitalize"
    }, [_vm._v(" " + _vm._s(_vm.$t(_vm.getOrderStatusByCTSId((_o$consumeTypeStatusI = o.consumeTypeStatusId) !== null && _o$consumeTypeStatusI !== void 0 ? _o$consumeTypeStatusI : 1))) + " ")])], 1), _c('div', {
      staticClass: "d-flex align-end mt-2"
    }, [_c('div', {
      staticClass: "text-caption flex-shrink-1 flex-grow-1 mr-4",
      staticStyle: {
        "width": "0"
      }
    }, [_c('div', [_vm._v(_vm._s((_o$addressInfo = o.addressInfo) === null || _o$addressInfo === void 0 ? void 0 : _o$addressInfo.date) + "/" + _vm._s((_o$addressInfo2 = o.addressInfo) === null || _o$addressInfo2 === void 0 ? void 0 : _o$addressInfo2.time))]), _c('div', {
      staticClass: "text-no-wrap text-truncate"
    }, [_vm._v(" " + _vm._s(o.addressInfo.deliveryMethod === _vm.DeliveryMethod.pickup ? o.addressInfo.firstName + ' ' + o.addressInfo.lastName : o.addressInfo.addressLine1 + '@' + o.addressInfo.firstName + ' ' + o.addressInfo.lastName) + " ")])]), _c('div', {
      staticClass: "text-body-1 text-no-wrap text-end"
    }, [_vm._v(_vm._s(_vm._f("priceDisplay")(o.totalPrice)))])])]);
  })], 2)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }