export const OrderInfo = {
    address: null,
    deliveryRule: {},
    deliveryMethod: null
}

export function getOrderStatusByCTSId(ctsId) {
    const id = parseInt(ctsId ?? 1)
    switch (id) {
        case 0:
        case 1:
            return 'notAccept'
        case 2:
        case 3:
        case 4:
            return 'accepted'
        case 5:
            return 'rejected'
        case 6:
            return 'finished'
        default:
            return 'unknown'
    }

}
