import hillo from 'hillo'
import GlobalConfig from '@/dataLayer/repository/GlobalSettingManager'
import {collection, doc, getDoc, query, setDoc} from "firebase/firestore";
import {currentUserId, db} from "@/plugins/fire-base";
import {resultOfId} from "@/dataLayer/service/OwnProducts";
import dayjs from "dayjs";

export const DeliveryMethod = {
    delivery: 'Delivery',
    pickup: 'Pickup',
}


export async function submitOrder(orderList, addressInfo, totalPrice, paid = false) {

    const paramData = {
        order: orderList,
        addressInfo: addressInfo,
        paymentState: paid ? 1 : 0
    }
    return await hillo.jsonPost(GlobalConfig.cloudRoot + '/orders/create', {
        jsonRequest: JSON.stringify(paramData),
        dataTerminalId: GlobalConfig.dataTerminalId,
        totalPrice,
        paymentState: paid ? 1 : 0
    })

}

export async function checkVoucher(code) {
    let result = ""
    try {
        const voucherList = await getTakeawayVoucher()
        const v = voucherList.find(it =>
            it?.code?.toUpperCase() === code.toUpperCase())
        if (v) {
            const dateOk = dayjs().isBetween(dayjs(v.activeFromDate + ' ' + v.activeFromTime),
                dayjs(v.activeToDate + ' ' + v.activeToTime))
            console.log(dateOk, 'code')
            if (v.active && (!v.maxCount || v.currentCount > 0) && dateOk) {
                return v
            }
        }
        return null

    } catch (e) {
        console.log(e)
    }
    return result

}

const storeTakeawayVoucher = 'storeTakeawayVoucher'

export async function getTakeawayVoucher() {
    const deviceId = GlobalConfig.deviceId
    const collectionRef =
        collection(db, storeTakeawayVoucher,
            deviceId, 'list')
    const res = query(collectionRef)
    return await resultOfId(res)
}

export async function voucherUsageInfo(code) {
    const voucherRef = doc(db, 'takeaway-voucher-user', currentUserId(),
        'list', code.toUpperCase())
    return (await getDoc(voucherRef)).data()?.used ?? false
}


export async function useVoucher(code) {
    const deviceId = GlobalConfig.deviceId
    const voucherList = await getTakeawayVoucher()
    const v = voucherList.find(it =>
        it.code.toUpperCase() === code.toUpperCase())
    console.log(v)
    const purchaseRef = doc(db, storeTakeawayVoucher,
        deviceId, 'list',
        code.toUpperCase())
    const currentInfo = await getDoc(purchaseRef)
    const voucherRef = doc(db, 'takeaway-voucher-user', currentUserId(),
        'list', code.toUpperCase())
    await setDoc(voucherRef, {used: true, timestamp: dayjs().valueOf()})
    console.log(currentInfo, 'info')
    await setDoc(purchaseRef, {currentCount: (currentInfo.data()?.currentCount ?? 1) - 1},
        {merge: true})
}
