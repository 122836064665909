var render = function render(){
  var _vm$dish$code;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "width": "100vw",
      "max-width": "600px"
    },
    model: {
      value: _vm.realShow,
      callback: function ($$v) {
        _vm.realShow = $$v;
      },
      expression: "realShow"
    }
  }, [_vm.dish ? _c('v-card', {
    staticClass: "d-flex flex-column align-center",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "id": "card",
      "max-height": "calc(100vh - 24px)"
    }
  }, [_c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 24px)",
      "overflow-y": "scroll",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "pa-4 white",
    staticStyle: {
      "width": "100%",
      "position": "sticky",
      "top": "0",
      "z-index": "100"
    }
  }, [_c('div', {
    staticClass: "text-h5 font-weight-black"
  }, [_vm._v(_vm._s((_vm$dish$code = _vm.dish.code) !== null && _vm$dish$code !== void 0 ? _vm$dish$code : '') + "." + _vm._s(_vm.dish.dishName))])]), _c('div', {
    staticClass: "text-body-2 text--secondary mt-1 px-4",
    domProps: {
      "innerHTML": _vm._s(_vm.dish.dishDesc)
    }
  }), _c('div', {
    staticClass: "pa-4"
  }, [_vm.haveImage ? _c('v-img', {
    attrs: {
      "width": "100%",
      "aspect-ratio": 16 / 12,
      "src": _vm.dish.cloudImage
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-card', [_c('v-responsive', {
          attrs: {
            "aspect-ratio": 1
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-center",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('v-icon', {
          attrs: {
            "large": ""
          }
        }, [_vm._v("mdi-image")])], 1)])], 1)];
      },
      proxy: true
    }], null, false, 82214034)
  }) : _vm._e()], 1), _vm.dish.modInfo && _vm.show && _vm.computedOption.length > 0 ? [_c('div', {
    staticClass: "text-body-2 px-4 font-weight-black mt-8 d-flex"
  }, [_vm._v(" 🥗 " + _vm._s(_vm.$t('Customize')) + " "), _c('v-spacer'), _vm.attrLimit !== -1 ? [_vm._v(" " + _vm._s(_vm.multipleSelected) + "/" + _vm._s(_vm.attrLimit) + " ")] : _vm._e()], 2), _c('div', {
    staticClass: "pb-16 px-4 mt-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._l(_vm.computedOption, function (item) {
    return [_c('div', {
      key: 'mod2head' + item.id,
      staticClass: "d-flex align-center text-caption font-weight-black mb-1"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.multiSelect !== '1' ? [_c('v-select', {
      key: 'mod2' + item.id,
      staticClass: "mb-2",
      attrs: {
        "outlined": "",
        "hide-details": "",
        "dense": "",
        "menu-props": {
          overflowY: true,
          offsetOverflow: true,
          offsetY: true
        },
        "item-value": "index",
        "item-text": _vm.itemText,
        "items": item.select
      },
      model: {
        value: _vm.mod[item.id],
        callback: function ($$v) {
          _vm.$set(_vm.mod, item.id, $$v);
        },
        expression: "mod[item.id]"
      }
    })] : _c('div', {
      key: 'mod2' + item.id,
      staticClass: "mt-2"
    }, [_c('v-item-group', {
      attrs: {
        "mandatory": item.required === '1',
        "multiple": item.multiSelect === '1'
      }
    }, _vm._l(item.select, function (s, index) {
      return _c('v-item', {
        key: 'mod111' + index
      }, [_c('v-card', {
        staticClass: "mb-2 text-body-2 d-flex",
        attrs: {
          "elevation": "0"
        }
      }, [_c('div', {
        staticClass: "text-body-2 flex-grow-1 mr-2"
      }, [_c('div', [_vm._v(_vm._s(s.text))]), _c('div', [_vm._v(_vm._s(s.priceInfo))])]), _c('div', {
        staticClass: "d-flex justify-center align-center"
      }, [s.count > 0 ? _c('v-btn', {
        attrs: {
          "icon": ""
        }
      }, [_c('v-icon', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.countMinus(s);
          }
        }
      }, [_vm._v(" mdi-minus-circle ")])], 1) : _vm._e(), s.count > 0 ? _c('div', {
        staticClass: "text-body-1 font-weight-bold mx-2"
      }, [_vm._v(_vm._s(s.count) + " ")]) : _vm._e(), _c('v-btn', {
        attrs: {
          "disabled": item.maxCount !== '-1' && _vm.currentGroupList(item) >= item.maxCount,
          "icon": ""
        }
      }, [_c('v-icon', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.countPlus(s);
          }
        }
      }, [_vm._v(" mdi-plus-circle ")])], 1)], 1)])], 1);
    }), 1)], 1)];
  })], 2)] : _vm._e(), _c('div', {
    staticClass: "flex-shrink-0",
    staticStyle: {
      "height": "144px",
      "width": "100%"
    }
  }), _c('v-card', {
    staticClass: "pa-2 px-4",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "width": "100%",
      "max-width": "600px"
    },
    attrs: {
      "tile": "",
      "color": "grey lighten-4"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-card', {
    staticClass: "pa-3 mr-2 d-flex align-center",
    staticStyle: {
      "border-radius": "24px"
    },
    attrs: {
      "elevation": "0",
      "color": "error lighten-4"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1), _c('v-spacer'), _vm.isActive ? [_c('div', {
    staticClass: "d-flex align-center grey lighten-5 mr-1",
    staticStyle: {
      "border-radius": "24px"
    }
  }, [_c('v-card', {
    staticClass: "pa-3",
    staticStyle: {
      "border-radius": "24px"
    },
    attrs: {
      "color": "grey lighten-3",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.count > 1 ? _vm.count-- : null;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-minus")])], 1), _c('div', {
    staticClass: "pa-2 mx-1"
  }, [_vm._v(" " + _vm._s(_vm.count) + " ")]), _c('v-card', {
    staticClass: "pa-3",
    staticStyle: {
      "border-radius": "24px"
    },
    attrs: {
      "color": "grey lighten-1",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.count++;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('v-card', {
    staticClass: "d-flex align-center justify-center pa-3 px-4 font-weight-black",
    staticStyle: {
      "border-radius": "24px"
    },
    attrs: {
      "elevation": "0",
      "color": "orange lighten-4 black--text"
    },
    on: {
      "click": _vm.submitModification
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-check ")]), _vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.realPrice * _vm.count)) + " ")], 1)] : [_c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_vm._v(_vm._s(_vm._f("priceDisplay")(_vm.realPrice)))])]], 2)])], 2)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }