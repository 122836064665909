<script>
import {DeliveryMethod} from "@/dataLayer/service/OrderService";
import {deliveryMethodOk} from "@/dataLayer/repository/localAddressInfo";

export default {
  name: "DeliveryMethodSelector",
  computed: {
    DeliveryMethod() {
      return DeliveryMethod
    }
  },
  methods: {
    deliveryMethodOk
  },
  props: {
    currentDeliveryMethod: {},
  }
}
</script>

<template>
  <div>
    <div class="text-body-2 font-weight-black">

      <v-icon
          color="indigo darken-4"
          small>mdi-rocket
      </v-icon>
      <v-icon
          color="pink darken-4"
          small>mdi-run-fast
      </v-icon>
      <v-icon
          color="black darken-4"
          small>mdi-map-marker
      </v-icon>
    </div>
    <div
        class="font-weight-black mb-2"
        style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr))">
      <v-card
          :disabled="!deliveryMethodOk(d)"
          elevation="0"
          :key="d"
          :tile="currentDeliveryMethod!==d"
          @click="$emit('select',d)"
          v-for="d in Object.values(DeliveryMethod)"
          :color="currentDeliveryMethod===d?'black white--text':'grey lighten-4'"
          class="pa-3 px-3 d-flex">
        <div class="text-body-1 font-weight-black">
          {{ $t(d) }}
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-icon
              v-if="deliveryMethodOk(d)"
              :color="currentDeliveryMethod===d?'white':'black'"
          >
            {{ d === DeliveryMethod.pickup ? 'mdi-shopping' : 'mdi-truck' }}
          </v-icon>
          <div
              v-else
              class="text-caption font-weight-black">
            Not avalibale
          </div>
        </div>
      </v-card>
    </div>
  </div>

</template>

<style scoped>

</style>
