<template>
  <div>
    <div
        class="d-flex flex-column justify-center"
        style="min-height: 100vh">
      <div class="pa-6 mt-n16">
        <template v-if="!error">
          <div class="text-body-1 font-weight-black">
            {{ $t('WelcomeTo') }}
          </div>
          <div class="text-h3 mt-4 font-weight-black">
            {{ restaurantInfo.name }}
          </div>
        </template>
        <template v-else>
          <div class="text-body-1 font-weight-black error--text">
            {{ $t('AnErrorAppeared') }}
          </div>
          <div class="text-h5">{{ errorMessage }}</div>
        </template>

        <template v-if="emailChecked">
          <v-card
              @click="reset"
              tile
              color="grey lighten-4 black--text"
              class="pa-4 mt-8 text-body-1 font-weight-black d-flex"
              outlined>
            {{ $t('Email') }}: {{ email }}
            <v-spacer></v-spacer>
            <v-icon color="black">mdi-close</v-icon>
          </v-card>
        </template>
        <template v-else>

          <div class=" text-body-2 mt-8">
            {{ $t('EnterEmail') }}
          </div>
          <div>
            <v-text-field
                :rules="emailRules"
                v-model="email"
                clearable
                :disabled="emailChecked"
                outlined
                hide-details
                style="border-radius: 0 !important;"
                class="mt-1"
                :placeholder="$t('EnterEmailPlaceholder')"
            />
          </div>
        </template>

        <template v-if="emailChecked">
          <div>
            <v-text-field
                :placeholder="$t('EnterPassword')"
                hide-details
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                outlined
                style="border-radius: 0 !important;"
                class="mt-4"
                @click:append="showPassword=!showPassword"
            />
          </div>
        </template>
        <v-card
            @click="startToLogin"
            tile
            :disabled="loading"
            color="black white--text"
            class="pa-4 mt-4 text-body-1 font-weight-black"
            outlined>
          {{ emailChecked ? userExist ? $t('Login') : $t('SignUp') : $t('LoginSignUp') }}
        </v-card>
        <v-card
            v-if="emailChecked"
            tile
            :disabled="loading"
            @click="showResetPasswordDialog=true"
            class="pa-4"
            color="grey lighten-5 text-body-1 font-weight-black"
            flat>
          {{ $t('ForgotPassword') }}
        </v-card>
        <v-card
            @click="loginAsGuest"
            v-if="!email"
            tile
            style="z-index: 1"
            :disabled="loading"
            class="pa-4"
            color="grey lighten-5 text-body-1 font-weight-black"
            flat>
          {{ $t('ContinueAsGuest') }}
        </v-card>

      </div>
      <div
          @click="confirmedAGB=!confirmedAGB"
          style="position: fixed; bottom: 48px; left: 24px;z-index: 0"
          class="text-caption mt-2  text--secondary">
        <div>
          <v-icon
              style="margin-top: -2px"
              small>
            {{ confirmedAGB ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
          </v-icon>
          {{ $t('AcceptAGB', {info : restaurantInfo.name}) }}
        </div>

        <div class="mt-2 font-weight-black">
          {{ $t('AadenPosShops') }}<span class="ml-1 black white--text px-1">Aaden POS</span>
        </div>

      </div>
    </div>
    <v-bottom-sheet v-model="showConfirmAGBDialog">
      <v-card
          dark
          color="black"
          class="pa-4"
      >
        <div class="text-h4 font-weight-black">{{$t('Attention')}}</div>
        <div class="text-body-1 my-4">{{ $t('ConfirmAGBFirst') }}</div>
        <div class="d-flex">
          <v-btn
              elevation="0"
              large
              width="50%"
              color="black white--text"
              tile
              class="text-body-2 text-capitalize font-weight-black"
              @click="showConfirmAGBDialog=false">{{ $t('Back') }}
          </v-btn>
          <v-btn
              large
              :loading="loading"
              elevation="0"
              width="50%"
              color="white black--text"
              tile
              style="text-transform: none !important"
              class="text-body-2 text-capitalize font-weight-black"
              @click="confirmAGB">{{ $t('AGBConfirmBtn') }}
          </v-btn>
        </div>

      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet v-model="showResetPasswordDialog">
      <v-card
          dark
          class="pa-4"
      >
        <div class="text-h4 font-weight-black">{{ $t('ForgottenPasswordTitle') }}</div>
        <div class="text-body-1 my-4">
          {{ $t('ForgottenPasswordHint') }}
        </div>
        <div class="d-flex">
          <v-btn
              elevation="0"
              large
              width="50%"
              color="black white--text"
              tile
              @click="showResetPasswordDialog=false">{{ $t('Back') }}
          </v-btn>
          <v-btn
              large
              :loading="loading"
              elevation="0"
              width="50%"
              color="white black--text"
              tile
              @click="confirmResetEmail">{{ $t('ResetPassword') }}
          </v-btn>
        </div>

      </v-card>
    </v-bottom-sheet>
  </div>

</template>

<script>
import {fetchSignInMethodsForEmail} from 'firebase/auth'
import {createUserWithPassword, FireBaseAuth, loginAsGuest, loginWithPassword, resetPassword} from "@/plugins/fire-base"
import {rulePassFor} from "@/dataLayer/service/utils"
import router from "@/plugins/router"
import {defaultRestaurantInfo, getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo"
import i18n from "@/i18n"

export default {
  name: "LoginPage",
  data: function () {
    return {
      restaurantInfo: defaultRestaurantInfo,
      confirmedAGB: false,
      showConfirmAGBDialog: false,
      showResetPasswordDialog: false,
      loading: false,
      errorMessage: '',
      email: '',
      emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || i18n.t('EnterValidEmail')
      ],
      emailChecked: false,
      showPassword: false,
      userExist: false,
      nextStep: null,
      password: ''
    };
  },
  computed: {
    error() {
      return !!this.errorMessage
    }
  },
  async mounted() {
    this.restaurantInfo = await getCurrentRestaurantInfo()
    const user = FireBaseAuth.currentUser
    if (user) {
      console.log(user, 'already login')
      router.push('/home')
    }
  },
  methods: {
    async confirmResetEmail() {
      this.loading = true
      await resetPassword(this.email)
      this.loading = false
      this.showResetPasswordDialog = false
    },
    async confirmAGB() {
      this.confirmedAGB = true
      this.loading = true
      await this.nextStep()
      this.loading = false
      this.showConfirmAGBDialog = false
    },
    async checkIfConfirmed(action) {
      if (!this.confirmedAGB) {
        this.showConfirmAGBDialog = true
        this.nextStep = action
      } else {
        await action()
      }

    },
    async loginAsGuest() {

      await this.checkIfConfirmed(loginAsGuest)

    },
    async startToLogin() {

      const handleError = (res) => {
        if (!res.result) {
          this.errorMessage = res.message
        }
      }
      await this.checkIfConfirmed(async () => {
        this.loading = true
        if (!this.emailChecked) {
          if (rulePassFor(this.emailRules, this.email)) {
            await this.checkIfUserExist()
          }
        } else {
          if (this.userExist) {
            const res = await loginWithPassword(this.email, this.password)
            handleError(res)
          } else {
            const res = await createUserWithPassword(this.email, this.password)
            handleError(res)
          }
        }
        this.loading = false
      })


    },
    reset() {
      this.emailChecked = false
      this.email = ''
    },
    async checkIfUserExist() {
      try {
        const methods = await fetchSignInMethodsForEmail(FireBaseAuth, this.email)
        this.userExist = methods.length > 0
        this.password = ''
        this.emailChecked = true
      } catch (e) {
        console.log(e)
      }


    }
  }
}
</script>

<style scoped>

</style>
