<template>
  <v-bottom-sheet
      dark
      v-model="realValue">
    <v-card
        tile
        width="100%">
      <div
          class="pa-6"
          style="width: 100vw">
        <div class="d-flex text-body-1 align-center font-weight-black">
          <template v-if="isPickUp">
            <v-icon class="mr-2">
              {{ isPickUp && !noAddressNow ? 'mdi-account' : 'mdi-account-alert' }}
            </v-icon>
            {{ isPickUp && !noAddressNow ? $t('ContactData') : $t('MissingContactData') }}
          </template>
          <template v-if="!isPickUp">
            <v-icon class="mr-2">
              {{ !isPickUp && !noAddressNow ? 'mdi-map' : 'mdi-map-marker-alert' }}
            </v-icon>
            {{ !isPickUp && !noAddressNow ? $t('AddressData') : $t('MissingAddressData') }}
          </template>
        </div>
        <div>
          <div v-if="noAddressNow">
            <empty-page
                :title="noAddressNow && !isPickUp ? $t('AddMissingAddressData') : $t('AddMissingContactData')"
                :tips="$t('AddMissingDataHint')"
                height="300px"></empty-page>
          </div>
          <div
              v-else
              class="my-8">
            <template v-for="a in currentAddressList">
              <v-card
                  width="100%"
                  elevation="0"
                  class="py-2 px-1 my-2"
                  @click="pop(a)"
                  :key="a.firstName+a.lastName+a.addressLine1+a.city">
                <div class="d-flex align-center">
                  <div class="text-h5 font-weight-black text-capitalize text-no-wrap text-truncate">
                    {{ a.firstName }} {{ a.lastName }}
                  </div>
                  <div
                      v-if="a.isDefault"
                      class="text-caption ml-2">
                    <v-icon>mdi-home-circle</v-icon>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                      @click.stop="deleteAddress(a)"
                      icon
                      small
                      class="ml-2">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                      @click.stop="selectDetail(a)"
                      icon
                      small
                      class="ml-2">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </div>
                <div>
                  <div
                      class="d-flex align-center text-caption text-truncate">
                    <v-icon
                        class="mr-1"
                        small
                        v-if="isSelectedAddress(a)"
                        color="amber">mdi-checkbox-marked-circle
                    </v-icon>
                    {{ isPickUp ? a.tel : a.addressLine1 + ', ' + a.plz + ' ' + a.city }}
                  </div>
                  <div
                      class="text-body-2 font-weight-black"
                      v-if="!canDelivery(a)">
                    {{ $t('AddressIsNotInDeliveryRange') }}
                    <v-icon
                        color="error lighten-2"
                        small>mdi-close-circle
                    </v-icon>
                  </div>
                </div>
              </v-card>
            </template>
          </div>
          <v-card
              @click="addAddress"
              elevation="0"
              color="white black--text"
              class="pa-4 text-center text-body-1 font-weight-black">
            <v-icon
                class="mr-2 ml-n1"
                color="black">
              mdi-plus
            </v-icon>
            {{ isPickUp ? $t('AddContactData') : $t('AddAddressData') }}
          </v-card>
        </div>
        <v-bottom-sheet v-model="showDeleteConfirmDialog">
          <v-card
              dark
              color="black"
              class="pa-4"
          >
            <div class="text-h4 font-weight-black">{{ $t('Attention') }}</div>
            <div class="text-body-1 my-4">{{ $t('UnrecoverableDelete') }}</div>
            <div class="d-flex">
              <v-btn
                  elevation="0"
                  large
                  width="50%"
                  color="black white--text"
                  tile
                  class="text-body-2 text-capitalize font-weight-black"
                  @click="showDeleteConfirmDialog=false">{{ $t('Back') }}
              </v-btn>
              <v-btn
                  large

                  elevation="0"
                  width="50%"
                  color="white black--text"
                  tile
                  class="text-body-2 text-capitalize font-weight-black"
                  @click="confirmDelete">{{ $t('Delete') }}
              </v-btn>
            </div>

          </v-card>
        </v-bottom-sheet>
        <add-new-address-dialog
            :old-id="oldId"
            @address-save="saveAndReload"
            :type="deliveryMethod"
            v-model="showAddNewAddressDialog"
        />
      </div>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import {DeliveryMethod} from '@/dataLayer/service/OrderService'
import {
  deleteAddress,
  getContactInfoFromAddress,
  getCurrentAddressList,
  getHashFieldForAddress,
  getUsableAddressList,
  resetAddressToAnyUsable,
  saveAddress
} from '@/dataLayer/repository/localAddressInfo'
import AddNewAddressDialog from '@/pages/widget/adress/AddNewAddressDialog'
import GlobalConfig from '@/dataLayer/repository/GlobalSettingManager'
import EmptyPage from "@/pages/widget/EmptyPage";
import {OrderInfo} from "@/dataLayer/repository/currentOrderState";


export default {
  name: "AddressSelector",
  components: {EmptyPage, AddNewAddressDialog},
  props: {
    value: {},
    initDeliveryMethod: {}
  },
  data: function () {
    return {
      currentAddressList: [],
      showAddNewAddressDialog: false,
      showDeleteConfirmDialog: false,
      currentAddress: null,
      deliveryMethod: DeliveryMethod.delivery,
      DeliveryMethod,
      realValue: null,
      oldId: null,
      GlobalConfig: GlobalConfig,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.realValue = val
        if (val) {
          this.reselectDeliveryMethod(this.initDeliveryMethod ?? DeliveryMethod.delivery)
          this.currentAddress = OrderInfo.address
        }
      }
    },
    realValue(val, old) {
      if (val !== old) {
        this.$emit('input', val)
      }
    }
  },

  computed: {
    noAddressNow() {
      return this.currentAddressList.length === 0
    },
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    },
    isPickUp() {
      return this.deliveryMethod === DeliveryMethod.pickup
    }
  },
  methods: {
    async confirmDelete() {
      this.showDeleteConfirmDialog = false
      if (this.oldId === this.currentAddress?.id) {
        this.$emit('save', null)
      }
      await deleteAddress(this.oldId, this.deliveryMethod)

      await this.loadAddress(this.deliveryMethod)
      const newAddressList = await getUsableAddressList(this.deliveryMethod)
      if (this.oldId === this.currentAddress?.id && newAddressList.length > 0) {
        await resetAddressToAnyUsable(this.deliveryMethod)
      }
    },
    isSelectedAddress(address) {
      return getHashFieldForAddress(this.currentAddress) === getHashFieldForAddress(address)
    },
    addAddress() {
      this.oldId = null
      this.showAddNewAddressDialog = true
    },
    async deleteAddress(address) {
      this.oldId = address.id
      this.showDeleteConfirmDialog = true
    },
    selectDetail(address) {
      this.oldId = address.id
      this.showAddNewAddressDialog = true
    },
    canDelivery(address) {
      return this.isPickUp || (address?.deliveryRule?.canDelivery ?? false)
    },
    async saveAndReload(address) {
      if (this.oldId) {
        await deleteAddress(this.oldId, this.deliveryMethod)
      }
      if (address.isDefault) {
        const currentAddressList = (await getCurrentAddressList(this.deliveryMethod))
            .filter(it => it?.isDefault)
        for (const it of currentAddressList) {
          await deleteAddress(it.id, this.deliveryMethod)
          it.isDefault = false
          await saveAddress(it, this.deliveryMethod)
        }
      }
      const id = await saveAddress(address, this.deliveryMethod)
      if (!this.isPickUp) {
        if ((await getUsableAddressList(DeliveryMethod.pickup)).length === 0) {
          const contactInfo = getContactInfoFromAddress(address)
          await saveAddress(contactInfo, DeliveryMethod.pickup)
        }
      }
      await this.loadAddress(this.deliveryMethod)
      this.showAddNewAddressDialog = false
      const add = (await getUsableAddressList(this.deliveryMethod)).find(it => it.id === id)
      if (add) {
        this.pop(add)
      }
    },
    async reselectDeliveryMethod(v) {
      this.deliveryMethod = v
      await this.loadAddress(v)
      if (this.currentAddressList.length === 0) {
        this.addAddress()
      }

    },
    async loadAddress(type) {
      this.currentAddressList = await getCurrentAddressList(type)

    },
    pop(d) {
      if (this.canDelivery(d)) {
        const data = Object.assign({}, {deliveryMethod: this.deliveryMethod}, d)
        this.$emit('save', data)
        this.realValue = false
      }
    },
  }
}
</script>

<style scoped>
.header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 3rem;
  padding: 0px 4.5rem;
  text-align: center;
  margin: 0px;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


</style>
