<template>
  <v-bottom-sheet
      scrollable
      inset
      v-model="realShow">
    <v-card
        color="white"
        class="pa-6"
        style="position: relative;padding-bottom: 72px;overflow-y: scroll">
      <div style="font-size: xx-large;font-weight: 900;font-family: 'Work Sans', sans-serif;line-height: 100%">
        {{ restaurantInfo.name }}
      </div>
      <div
          class="d-flex align-center mt-4"
          style="font-size: small">
        <span>{{ todayWeekday }} {{ todayOpenTimeDisplay }}</span>
      </div>
      <blockquote
          v-html="restaurantInfo.takeawayAnnouncementHead ? restaurantInfo.takeawayAnnouncementHead : restaurantInfo.buffetAnnouncementHead"
          class="mt-4"></blockquote>
      <div

          v-html="restaurantInfo.takeawayAnnouncementBody ? restaurantInfo.takeawayAnnouncementBody : restaurantInfo.buffetAnnouncementBody"
          class="d-flex align-center mt-4"
          style="font-size: small;white-space: pre-line;">
      </div>
      <h3 class="mt-4">{{ $t('Adresse') }}</h3>
      <div
          class="mt-2"
          style="font-size: small">
        <p>{{ restaurantInfo.adress1 }}<br>
          {{ restaurantInfo.postCode }} {{ restaurantInfo.city }}</p>
      </div>
      <h3>{{ $t('Contact') }}</h3>
      <div
          class="mt-2"
          style="font-size: small">
        <div>{{ $t('phone') }}: {{ restaurantInfo.telephone }}</div>
        <div>{{ $t('Email') }}: {{ restaurantInfo.emailAddress }}</div>
      </div>
      <h3 class="mt-2">{{ $t('openingTimes') }}</h3>
      <div
          class="mt-2"
          style="font-size: small">
        <div
            v-for="(item, index) in openingTimeList"
            :key="index"
            class="d-flex mt-1 justify-space-between">
          <div class="font-weight-bold">
            {{ item.weekday }}
          </div>
          <div class="text-right">
            <span
                v-for="(timeItemForAll, n) in item.openTime"
                :key="n">
                {{ timeItemForAll.join(' - ') }}<br>
            </span>
          </div>
        </div>
      </div>
      <v-btn
          @click="cancel"
          style="position: absolute;right: 16px;top:16px;z-index: 5;background: white"
          icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

  </v-bottom-sheet>
</template>

<script>

import dayjs from "dayjs";

export default {
  name: "RestaurantDetailPage",
  data: function () {
    return {
      todayWeekday: dayjs().format('dddd')
    };
  },
  props: {show: {}, restaurantInfo: {}, openingTimeList: {}, todayOpenTimeDisplay: {}, isOpen: {}},
  computed: {
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
  },
  async mounted() {
  }

}
</script>

<style scoped>

</style>
