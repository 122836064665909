import {getRestaurantInfo} from '@/dataLayer/service/api'
import {executeQuery} from "@/dataLayer/service/utils";
import {collection, doc, getDoc, query, setDoc} from "firebase/firestore";
import {db, getUrlForFile} from "@/plugins/fire-base";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";
import {orderBy} from "lodash-es";
import {Remember} from "@/locales";

export const defaultRestaurantInfo = {
    adress1: '',
    adress2: '',
    city: '',
    name: '',
    postCode: '',
    currentlyOpening: 0,
    emailAddress: '',
    inhalterLastName: '',
    inhalterName: '',
    latitude: '',
    longitude: '',
    state: '',
    takeawayPriceModification: 0,
    taxNumber: '',
    telephone: ''
}


let RestaurantInfo = null

export async function getCurrentRestaurantInfo() {
    if (RestaurantInfo == null) {
        RestaurantInfo = Object.assign({}, defaultRestaurantInfo, (await getRestaurantInfo(Remember.requestLanguage, 'restaurantInfo'))[0])

        const reg = new RegExp('<BR>', 'g')
        RestaurantInfo.name = RestaurantInfo.name.replace(reg, ' ').replace('-', '‧')
        //这里修正了 currentlyOpening 始终为1
        RestaurantInfo.currentlyOpening = parseInt(RestaurantInfo.currentlyOpening) ?? 1
        // RestaurantInfo.currentlyOpening =  1
    }
    return RestaurantInfo
}

const path = 'activity-post'

export async function getActivityPosts() {
    try {

        const list = await executeQuery(query(collection(db, path,
            GlobalConfig.deviceId.toString(),
            'a-post-by-remote-id')))
        for (const it of list) {
            try {
                it.imageRealUrl = await getUrlForFile(it.meta_imagePath)
            } catch (e) {
                it.imageRealUrl = ''
            }

        }
        return sortDesc(list, 'createdAt')
    } catch (e) {
        console.warn(e)
        return []
    }

}

export async function likePost(id) {
    const newLike = ((await getDoc(doc(db, path, GlobalConfig.deviceId.toString(),
        'a-post-by-remote-id', id))).get('like') ?? 0) + 1
    await setDoc(doc(db, path, GlobalConfig.deviceId.toString(),
        'a-post-by-remote-id', id), {like: newLike}, {merge: true})


}

export function sortDesc(array, key) {
    return orderBy(array, [key], ['desc'])
}
