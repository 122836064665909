var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dishCount > 0 ? _c('v-card', {
    staticClass: "pa-4 font-weight-black",
    staticStyle: {
      "position": "fixed",
      "bottom": "76px",
      "left": "16px",
      "right": "16px",
      "box-shadow": "rgba(0,0,0,.04) 0 -4px 12px",
      "z-index": "100 !important"
    },
    attrs: {
      "disabled": _vm.disable,
      "dark": "",
      "color": _vm.disable ? 'grey darken-4' : 'grey darken-4'
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-badge', {
    staticClass: "mr-4",
    attrs: {
      "content": _vm.dishCount,
      "overlap": "",
      "color": "white black--text darken-4",
      "offset-x": "12"
    }
  }, [_c('v-icon', [_vm._v("mdi-shopping-outline")])], 1), _vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.$t('Basket')) + " ")];
  }), _c('v-spacer'), _vm.restaurantIsOpen ? [_c('div', {
    staticClass: "text-body-2 text-end font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.totalPrice)) + " "), !_vm.isPickUp ? _c('div', {
    staticClass: "text-caption mt-n1",
    staticStyle: {
      "font-size": "10px !important"
    }
  }, [_vm.differentPrice > 0 ? [_vm._v(" " + _vm._s(_vm.$t('MinimumOrderValue')) + " " + _vm._s(_vm._f("priceDisplay")(_vm.startPrice)) + " ")] : _vm.differentFreePrice > 0 ? [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.differentFreePrice)) + _vm._s(_vm.$t('TillYouReachedDeliveryFree')) + " ")] : _vm.differentFreePrice < 0 ? [_vm._v(" 🎉 " + _vm._s(_vm.$t('FreeDelivery')) + " ")] : _vm._e()], 2) : _vm._e()])] : [_c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('ShopIsClosed')) + " ")])]], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }