<template>
  <div class="grey lighten-4">
    <div class="pa-4 pt-8 flex-shrink-0 white">
      <div class="text-body-2 font-weight-black">{{ $t('MenuAtGlance') }}</div>
      <div class="text-h4 mt-4 font-weight-black">
        {{ $t('SpecialForYou') }} 💖
      </div>
      <delivery-method-selector
          v-if="deliveryMethod"
          class="mt-8 mb-2"
          :current-delivery-method="deliveryMethod"
          @select="updateDeliveryMethod"
      />
      <current-address-display
          v-if="deliveryMethod"
          class="mb-4 mt-8 "
          @click="showAddressDialog=true"
          :address-info="addressInfo"
          :is-pick-up="isPickUp"/>
    </div>
    <v-banner
        single-line
        app
        color="white"
        sticky
        class="pa-0"
        style="top:-1px !important;box-shadow: rgba(0,0,0,.11) 0 4px 4px;">
      <div
          class="d-flex align-center"
          v-if="!showSearch">
        <v-btn
            @click="showSearchField"
            icon
            color="black"
            class="grey lighten-4 "
            small>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <div
            ref="categoryTab"
            class="px-2 flex-grow-1 mr-2"
            style="align-items: center;display: grid;grid-auto-flow: column;
          grid-auto-columns: min-content;
          height: 36px;
          grid-gap: 4px;
          overflow-x: scroll">
          <div
              v-for="(category) in categoryWithDishesInfo"
              @click="gotoCategory(category)"
              :id="'navItem'+category.id"
              style="border-radius:50rem;height: 32px;padding: 5px 12px;font-size: 14px !important;"
              :class="category.id===activeCategoryId?'black white--text':''"
              class="text-no-wrap mr-2 text-body-2 font-weight-black text-capitalize d-flex justify-center align-center"
              :key="category.id">
            {{ category.name }}
          </div>
        </div>
        <v-btn
            @click="expandAllCategories=true"
            icon
            color="black"
            class="grey lighten-4 "
            small>
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </div>
      <div
          class="d-flex"
          v-else>
        <v-text-field
            hide-details
            class="ma-0"
            outlined
            autofocus
            @input="showAllSearchDishes"
            :placeholder="$t('SearchPlaceHolder')"
            v-model="searchWord"
            @click:append="showSearch=false;searchWord=''"
            append-icon="mdi-close"
        ></v-text-field>
      </div>
      <div
          @click="showDeliveryRuleInfo=true"
          class="mt-4"
          v-if="!isPickUp&&hasAddress">
        <div
            class="rounded"
            style="background-color: #f0f0f0;width: 100%">
          <v-progress-linear
              :value="totalPriceWithoutMod/freePrice*100"
              color="secondary"
              height="8"
              :buffer-value="currentBufferValue"
              rounded></v-progress-linear>
        </div>
        <div class="text-caption d-flex mt-2 black--text font-weight-black">
          <div>
            <template v-if="differentPrice<0">
              {{ -differentPrice|priceDisplay }}{{ $t('MinimumPriceForDelivery') }}
            </template>
            <template v-else-if="differentFreePrice>0">
              {{ differentFreePrice |priceDisplay }}{{ $t('TillYouReachedDeliveryFree') }}
            </template>
            <template v-else-if="differentFreePrice<0">
              🎉 {{ $t('ReachedFreeDeliveryPrice') }}
            </template>
            <template v-else-if="canOrder">
              {{ $t('ReachedAndOrderNow') }}
            </template>

          </div>
          <v-spacer></v-spacer>
          <v-icon
              color="black"
              small>mdi-information-slab-circle-outline
          </v-icon>
        </div>
      </div>

    </v-banner>
    <template>
      <div
          v-if="!searchWord"
          class="itemContainer pb-16">
        <div
            :key="category.id"
            v-for="category in categoryWithDishesInfo">
          <div
              v-intersect="{handler:e=>onIntersect(e,category),options:{
          }}"
              :id="'category'+category.id"></div>
          <div class="pa-4 mt-6">
            <div class="text-h5 font-weight-black">{{ category.name }}</div>
            <div class="text-body-2">{{ category.desc }}</div>
          </div>
          <div style="display: grid;grid-template-columns: repeat(auto-fill,minmax(300px,1fr))">
            <dish-card
                :count="dishCountInCart(dish.code)"
                @click="dishClick(dish)"
                @detail="dishClick(dish,true)"
                v-for="dish in category.dishes"
                :key="dish.id"
                :image-root="GlobalConfig.imageRoot"
                :dish="dish"/>
          </div>

        </div>
      </div>
      <div
          v-else
          class="itemContainer"
          style="min-height: 100vh">
        <template v-if="filteredDish.length>0">
          <dish-card
              :count="dishCountInCart(dish.code)"
              @click="dishClick(dish)"
              @detail="dishClick(dish,true)"
              class="mt-2"
              v-for="dish in filteredDish"
              :key="dish.id"
              :image-root="GlobalConfig.imageRoot"
              :dish="dish"/>
        </template>
        <template v-else>
          <div
              class="d-flex align-center justify-center flex-column pa-8 pt-16 mt-16">
            <v-icon
                size="64"
                class="mt-n16">mdi-text-search
            </v-icon>
            <div class="mt-4">{{ $t('NoSearchResults') }}</div>
          </div>
        </template>

      </div>
    </template>
    <cart-button
        v-if="!showCart"
        @click="showCart=true"
        :dish-count="dishCount"
        :total-price="totalPriceWithoutMod"
        :is-pick-up="isPickUp"
        :start-price="startPrice"
        :free-price="freePrice"
    ></cart-button>
    <v-bottom-sheet
        scrollable
        fullscreen
        v-model="showCart">
      <v-card
          tile
          style="overflow: scroll">
        <div class="pa-4 pt-8">
          <v-btn
              icon
              @click="showCart=false"
              large
              color="black"
              class="ml-n2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <div class="text-h4 font-weight-black">
            {{ $t('ShoppingCart') }}
          </div>
          <div class="mt-8">
            <div class="text-body-2 font-weight-black">{{ $t('IncludeVat') }}</div>
            <div class="mt-2 text-h4 font-weight-black">
              {{ totalPriceWithoutMod | priceDisplay }}
            </div>
          </div>
          <div class="pb-16 mt-12">
            <div class="d-flex text-body-2 font-weight-black">
              #
              <div class="ml-6">{{ $t('Items') }}</div>
            </div>

            <v-card
                elevation="0"
                style="border-radius: 12px;"
                v-for="dish in cartList"
                class="d-flex mb-4 text-body-1 align-baseline"
                :key="dish.id"
            >
              <div class="font-weight-black">{{ dish.count }}&times;</div>
              <div class="mx-3 flex-grow-1">
                <div>{{dish.code ?? ''}}.{{ dish?.dishName ?? dish?.name ?? '' }}</div>
                <div
                    v-if="dish.displayApply.length>0"
                    class="text-body-2 text--secondary mt-1">
                  {{ dish.displayApply.map(it => it.value).join(', ') }}
                </div>
                <div
                    v-if="dish.note"
                    class="text-body-2 text--secondary mt-1">
                  # {{ dish.note }}
                </div>
              </div>
              <div class="font-weight-black mr-2 text-no-wrap">
                {{ dish.realPrice | priceDisplay }}
              </div>
              <div
                  class=" d-flex align-center"
                  style="border-radius: 24px;">
                <v-card
                    color="grey lighten-2"
                    class="pa-1"
                    style="border-radius: 24px"
                    elevation="0"
                    @click="editDishNote(dish)">
                  <v-icon>mdi-note-edit</v-icon>
                </v-card>
                <v-card
                    color="grey lighten-2"
                    class="pa-1"
                    style="border-radius: 24px"
                    elevation="0"
                    @click="dish.change(-1)">
                  <v-icon>mdi-minus</v-icon>
                </v-card>
                <v-card
                    color="grey lighten-1"
                    class="pa-1"
                    style="border-radius: 24px"
                    elevation="0"
                    @click="dish.change(1)">
                  <v-icon>mdi-plus</v-icon>
                </v-card>
              </div>
            </v-card>
          </div>
          <cart-button
              style="bottom: 12px !important;"
              @click="$router.push('/checkout')"
              :dish-count="dishCount"
              :total-price="totalPriceWithoutMod"
              :is-pick-up="isPickUp"
              :start-price="startPrice"
              :free-price="freePrice"
              :disable="!canOrder"
              :restaurant-is-open="restaurantIsOpen"
          >
            {{ $t('ToCashOut') }}
          </cart-button>
        </div>

      </v-card>
    </v-bottom-sheet>
    <address-selector
        :init-delivery-method="deliveryMethod"
        @save="save"
        v-model="showAddressDialog"
    />
    <dish-detail-sheet
        :show="showDishDetailSheet"
        @modification-cancel="cancelEdit"
        @modification-submit="submitModification"
        :image-root="GlobalConfig.imageRoot"
        :dish="currentDish"></dish-detail-sheet>
    <restaurant-detail-page
        @close="showRestaurantInfo=false"
        :show="showRestaurantInfo"
        :opening-time-list="openingTimeList"
        :is-open="isOpen"
        :today-open-time-display="todayOpenTimeDisplay"
        :restaurant-info="restaurantInfo">
    </restaurant-detail-page>
    <v-dialog
        fullscreen
        v-model="expandAllCategories">
      <v-card tile>
        <div>
          <div class="d-flex align-center px-4 py-3 grey lighten-4">
            <div class="text-h6 font-weight-black">
              {{ $t("Category") }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
                icon
                @click="expandAllCategories = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="mt-2 px-4 pb-4">
            <div
                class="mb-4 d-flex"
                :key="category.id"
                @click="gotoCategory(category); expandAllCategories = false"
                v-for="category in categoryWithDishesInfo">
              <div>
                <div class="text-body-1 font-weight-black">
                  {{ category.name }}
                </div>
                <div class="text-body-2 text--secondary">
                  {{ category.desc || 'No description' }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <v-avatar size="36">
                <v-img
                    :src="GlobalConfig.resourceRoot+category.dcImage"></v-img>
              </v-avatar>


            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
        fullscreen
        v-model="showCloseDialog">
      <v-card tile>
        <v-fade-transition>
          <div
              class="pa-4 text-center d-flex flex-column align-center justify-center"

          >
            <div style="height: 45vh">
              <lottie-animation
                  style="height: 60vh"
                  v-cloak
                  ref="animation"
                  :animationData="require('@/assets/closed.json')"/>
            </div>

            <div class="text-h4 font-weight-black">{{ $t('Sorry') }}</div>
            <div class="my-4 text-body-1">{{
                $t('CurrentlyNotAcceptingOrders')
              }} <span>☎️</span>️
              <a
                  class="ml-2"
                  :href="'tel://'+restaurantInfo.telephone">
                {{ restaurantInfo.telephone }}</a>
            </div>
            <v-btn
                elevation="0"
                class="pa-2 mb-4"
                color="grey lighten-4"
                @click="openTimeDialog = true">{{
                $t('showOpeningTimes')
              }}
            </v-btn>
            <v-card
                @click="showCloseDialog=false"
                flat
                tile
                class="pa-4 d-flex align-center justify-center text-body-1 font-weight-black"
                color="grey lighten-4"
                style="position: fixed;bottom: 0;left: 0;right: 0;width: 100vw">
              <v-icon left>mdi-check</v-icon>
              {{ $t('Confirm') }}
            </v-card>
          </div>
        </v-fade-transition>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openTimeDialog">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ $t('openingTimes') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-2">
            <div
                v-for="(item, index) in openingTimeList"
                :key="index"
                class="d-flex mt-1 justify-space-between">
              <div class="font-weight-bold">
                {{ item.weekday }}
              </div>
              <div class="text-right">
            <span
                v-for="(timeItemForAll, n) in item.openTime"
                :key="n">
                {{ timeItemForAll.join(' - ') }}<br>
            </span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNoteDialog">
      <v-card
          class="pa-4"
          width="400px">
        <div class="text-h4 font-weight-black">{{ $t('AddNote') }}</div>
        <v-textarea
            class="mt-4"
            :placeholder="$t('FurtherDeliveryInstructions')"
            outlined
            v-model="noteInput"></v-textarea>
        <v-btn
            @click="saveDishNote"
            block
            large
            elevation="0"
            color="black white--text"
            class="text-capitalize text-body-2 font-weight-black">
          {{ $t('Save') }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-btn
        v-if="!showBackToTop"
        @click="$vuetify.goTo(0)"
        right
        bottom
        fixed
        fab
        :style="dishCount>0?{bottom:'144px'}:{bottom: '96px'}"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-bottom-sheet
        dark
        v-model="showDeliveryRuleInfo">
      <v-card
          class="pa-4"
          v-if="showDeliveryRuleInfo">
        <div class="text-h4">
          📋
        </div>
        <div class="text-body-1 font-weight-black">{{ $t('DeliveryRules') }}</div>
        <div class="text-body-2 font-weight-black mt-4">
          <div>
            {{ $t('MinimumOrderValue') }}: {{ startPrice | priceDisplay }}
          </div>
          <div>
            {{ $t('FreeDeliveryFrom') }} {{ freePrice | priceDisplay }}
          </div>
          <div>
            {{ $t('DeliveryCost') }}: {{ deliveryCost | priceDisplay }}
          </div>
        </div>
        <v-btn
            class="mt-4 text-body-1 font-weight-black"
            large
            @click="showDeliveryRuleInfo=false"
            block
            light
            color="white">
          {{ $t('Confirm') }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import DishDetailSheet from '@/components/DishDetailSheet'
import {getCategoryWithDishes, getOpeningTime, getWeekDay, isInOpenTime} from '@/dataLayer/service/api'
import {Cart} from '@/dataLayer/service/CartService'
import {defaultRestaurantInfo, getCurrentRestaurantInfo} from '@/dataLayer/repository/restaurantInfo'
import DishCard from '@/components/DishCard'
import RestaurantDetailPage from "@/components/fragment/RestaurantDetailPage"
import dayjs from "dayjs"
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import LottieAnimation from 'lottie-web-vue' // import lottie-web-vue
import i18n from "@/i18n"
import AddressSelector from "@/pages/widget/adress/AddressSelector"
import {OrderInfo} from '@/dataLayer/repository/currentOrderState'
import {DeliveryMethod} from '@/dataLayer/service/OrderService'
import {sumBy} from "lodash-es";
import {firstAvailableDeliveryMethod, resetAddressToAnyUsable} from "@/dataLayer/repository/localAddressInfo";
import DeliveryMethodSelector from "@/pages/widget/adress/DeliveryMethodSelector.vue";
import CurrentAddressDisplay from "@/pages/widget/adress/CurrentAddressDisplay.vue";
import CartButton from "@/pages/widget/CartButton";
import {constant, times} from "lodash";


export default {
  name: "MainPage",
  components: {
    CartButton,
    CurrentAddressDisplay,
    DeliveryMethodSelector,
    AddressSelector,
    RestaurantDetailPage,
    DishCard,
    DishDetailSheet,
    LottieAnimation,
  },
  data: function () {
    return {
      expandAllCategories: false,
      showAddressDialog: false,
      showCart: false,
      openTimeDialog: false,
      showDishDetailSheet: false,
      showSearch: false,
      activeCategoryId: null,
      getFreePrice: 0,
      userAddress: undefined,
      addressInfo: null,
      deliveryMethod: null,
      startPrice: 0,
      deliveryCost: 0,
      freePrice: 0,
      showNoteDialog: null,
      noteInput: '',
      showDeliveryRuleInfo: null,
      showRestaurantInfo: false,
      currentDish: null,
      categoryWithDishesInfo: [],
      restaurantInfo: defaultRestaurantInfo,
      cart: Cart,
      searchWord: null,
      openingTimeList: [],
      todayOpenTime: {},
      isOpen: false,
      showCloseDialog: false,
      restaurantIsOpen: true,
      GlobalConfig,
      showBackToTop: true
    }
  },
  computed: {
    DeliveryMethod() {
      return DeliveryMethod
    },
    currentBufferValue() {
      return (this.totalPriceWithoutMod < this.startPrice ? this.startPrice : this.freePrice) / this.freePrice * 100
    },
    hasAddress: function () {
      return this.addressInfo !== null
    },
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      return parseFloat(this.totalPriceWithoutMod)
    },
    differentPrice: function () {
      return this.totalPriceBeforeDiscount - this.startPrice
    },
    differentFreePrice: function () {
      return this.freePrice - this.totalPriceBeforeDiscount
    },
    todayOpenTimeDisplay: function () {
      return this.todayOpenTime?.openTime?.map(a => a.join('-')).join(', ') ?? i18n.t("TodayClosed")
    },
    canOrder: function () {
      return (this.isPickUp || !this.hasAddress || this.differentPrice >= 0) && this.restaurantIsOpen
    },
    filteredDish: function () {
      return this.categoryWithDishesInfo.reduce((arr, i) => {
        arr.push(...i.dishes.filter(d => {
          return d.code.toLowerCase().includes(this.searchWord) || d.dishName.toLowerCase().includes(this.searchWord)
        }))
        return arr
      }, []).sort((a, b) => a.code > b.code ? 1 : -1)
    },
    isPickUp() {
      return this.deliveryMethod === DeliveryMethod.pickup
    }
  },
  methods: {
    showSearchField() {
      this.searchWord = ''
      this.showSearch = true
      this.showAllSearchDishes()
    },
    dishClick(dish, forceDetail = false) {
      if (dish.haveMod !== '0' || forceDetail) {
        this.currentDish = dish
        this.showDishDetailSheet = true
      } else {
        this.addDish(dish)
      }
    },
    save(addressInfo) {
      OrderInfo.address = addressInfo
      OrderInfo.deliveryRule = addressInfo?.deliveryRule
      this.updateAllInfo()
      this.showAddressDialog = false
    },
    updateAllInfo() {
      this.addressInfo = OrderInfo.address
      this.deliveryMethod = OrderInfo.deliveryMethod
      this.deliveryCost = 0
      this.freePrice = 0
      this.startPrice = 0
      if (OrderInfo.deliveryRule) {
        const rule = OrderInfo.deliveryRule
        if (rule.priceMod) {
          this.deliveryCost = rule.priceMod
        }
        this.startPrice = rule.startPrice
        this.freePrice = rule.freePrice
      }
    },
    gotoCategory: function (category) {
      this.$vuetify.goTo(`#category${category.id}`, {offset: 56})
      setTimeout(() => {
        this.changeActiveCategoryId(category.id)
      }, 500)

    },
    showAllSearchDishes: function () {
      this.$vuetify.goTo(324)
    },
    addDish: function (dish, count = 1) {
      this.updateCart(dish, count)
    },
    saveDishNote() {
      this.$set(this.currentDish, 'note', this.noteInput)
      this.showNoteDialog = false
    },
    submitModification: function (mod, count = 1) {
      const apply = []
      const dish = this.currentDish
      if (dish.modInfo) {
        for (const i of dish.modInfo) {
          const item = {}
          item.groupId = i.id
          if (i.multiSelect === '1') {
            item.selectId = i.select.map(x => {
              return times(x.count,constant(x.value))
            }).flat()
          } else {
            if (i.required !== '1') {
              item.selectId = i.selectValue.filter((s, index) => {
                return [(mod[i.id] - 1 ?? [])].flat().includes(index)
              })
            } else {
              item.selectId = i.selectValue.filter((s, index) => {
                return [(mod[i.id] ?? [])].flat().includes(index)
              })
            }
          }
          if (i.required === '1' && item.selectId === '') {
            item.selectId = i.selectValue[0]
          }
          apply.push(item)
        }
        dish.apply = apply
      }

      this.addDishWithMod(dish, count)
      this.currentDish = null
      this.showDishDetailSheet = false
    },
    changeActiveCategoryId(categoryId) {
      this.activeCategoryId = categoryId
      this.$refs.categoryTab.scrollLeft = [...this.$refs.categoryTab.childNodes.values()]
          .find(it => it.id === 'navItem' + categoryId).offsetLeft - 56
    },
    onIntersect(entries, category) {
      if (entries[0].boundingClientRect.top < 64) {
        this.changeActiveCategoryId(category.id)
      }
    },
    addDishWithMod: function (dish, count) {
      this.updateCart(dish, count)
    },
    updateCart: function (dish, count) {
      try {
        window.navigator.vibrate(300)
      } catch (e) {
        console.log(e, 'error on update cart')
      }
      setTimeout(() => {
        this.cart.add(dish, count)
      }, 3)
    },
    dishCountInCart(code) {
      return sumBy(this.cartList.filter(it => it.code === code), 'count')
    },
    cancelEdit() {
      this.$nextTick(() => {
        this.showDishDetailSheet = false
      })
    },
    async editDishNote(dish) {
      this.currentDish = dish
      this.noteInput = dish.note??''
      this.showNoteDialog = true
    },
    async updateDeliveryMethod(deliveryMethod) {
      OrderInfo.deliveryMethod = deliveryMethod
      OrderInfo.address = null
      if (deliveryMethod) {
        await resetAddressToAnyUsable(deliveryMethod)
      }

      this.updateAllInfo()
    }
  },
  async mounted() {
    this.categoryWithDishesInfo = await getCategoryWithDishes('DE')
    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.openingTimeList = await getOpeningTime()
    this.todayOpenTime = (this.openingTimeList).find(item => parseInt(item.id) === parseInt(getWeekDay()))
    this.isOpen = isInOpenTime(dayjs().format('HH:mm'), this.todayOpenTime?.openTime)
    this.restaurantIsOpen = (this.restaurantInfo?.currentlyOpening ?? true) && firstAvailableDeliveryMethod()
    this.showCloseDialog = !this.restaurantIsOpen
    await this.updateDeliveryMethod(
        OrderInfo.deliveryMethod ?? firstAvailableDeliveryMethod()
    )
  }
}
</script>
<style>
::-webkit-scrollbar {
  display: none;
}
</style>


