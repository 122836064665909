var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "scrollable": "",
      "inset": ""
    },
    model: {
      value: _vm.realShow,
      callback: function ($$v) {
        _vm.realShow = $$v;
      },
      expression: "realShow"
    }
  }, [_c('v-card', {
    staticClass: "pa-6",
    staticStyle: {
      "position": "relative",
      "padding-bottom": "72px",
      "overflow-y": "scroll"
    },
    attrs: {
      "color": "white"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "xx-large",
      "font-weight": "900",
      "font-family": "'Work Sans', sans-serif",
      "line-height": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.restaurantInfo.name) + " ")]), _c('div', {
    staticClass: "d-flex align-center mt-4",
    staticStyle: {
      "font-size": "small"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.todayWeekday) + " " + _vm._s(_vm.todayOpenTimeDisplay))])]), _c('blockquote', {
    staticClass: "mt-4",
    domProps: {
      "innerHTML": _vm._s(_vm.restaurantInfo.takeawayAnnouncementHead ? _vm.restaurantInfo.takeawayAnnouncementHead : _vm.restaurantInfo.buffetAnnouncementHead)
    }
  }), _c('div', {
    staticClass: "d-flex align-center mt-4",
    staticStyle: {
      "font-size": "small",
      "white-space": "pre-line"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.restaurantInfo.takeawayAnnouncementBody ? _vm.restaurantInfo.takeawayAnnouncementBody : _vm.restaurantInfo.buffetAnnouncementBody)
    }
  }), _c('h3', {
    staticClass: "mt-4"
  }, [_vm._v(_vm._s(_vm.$t('Adresse')))]), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "font-size": "small"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.restaurantInfo.adress1)), _c('br'), _vm._v(" " + _vm._s(_vm.restaurantInfo.postCode) + " " + _vm._s(_vm.restaurantInfo.city))])]), _c('h3', [_vm._v(_vm._s(_vm.$t('Contact')))]), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "font-size": "small"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('phone')) + ": " + _vm._s(_vm.restaurantInfo.telephone))]), _c('div', [_vm._v(_vm._s(_vm.$t('Email')) + ": " + _vm._s(_vm.restaurantInfo.emailAddress))])]), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(_vm._s(_vm.$t('openingTimes')))]), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "font-size": "small"
    }
  }, _vm._l(_vm.openingTimeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex mt-1 justify-space-between"
    }, [_c('div', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.weekday) + " ")]), _c('div', {
      staticClass: "text-right"
    }, _vm._l(item.openTime, function (timeItemForAll, n) {
      return _c('span', {
        key: n
      }, [_vm._v(" " + _vm._s(timeItemForAll.join(' - '))), _c('br')]);
    }), 0)]);
  }), 0), _c('v-btn', {
    staticStyle: {
      "position": "absolute",
      "right": "16px",
      "top": "16px",
      "z-index": "5",
      "background": "white"
    },
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }