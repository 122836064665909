import firebase from 'firebase/compat'
import {collection, getDocs, query} from 'firebase/firestore'
import {
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithEmailAndPassword,
    signOut,
    updateProfile
} from 'firebase/auth'
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import router from "@/plugins/router";

const FirebaseConfig = {
    apiKey: 'AIzaSyCtvQ3d-HAtHTUg_-505c-qXRnlz8RlZeg',
    authDomain: 'aaden-saas.firebaseapp.com',
    projectId: 'aaden-saas',
    storageBucket: 'aaden-saas.appspot.com',
    messagingSenderId: '169167876904',
    appId: '1:169167876904:web:b83934e5a34d1cbfcc161d',
    measurementId: 'G-QRPH7NLDZS',
}
const websiteUrl = 'websiteUrls'

export const app = firebase.initializeApp(FirebaseConfig)

export const db = firebase.firestore(app)
export const FireBaseAuth = getAuth(app)
const storage = getStorage(app)


export async function getUrlForFile(fileUrl) {
    return await getDownloadURL(ref(storage, fileUrl))
}

export async function checkNewRecords() {
    const collectionRef = collection(db, websiteUrl)
    const res = query(collectionRef)
    return (await getDocs(res)).docs.map(it => it.data())
}

export async function loginWithPassword(email, password) {

    try {
        await signInWithEmailAndPassword(FireBaseAuth, email, password)
        return {result: true, message: ''}
    } catch (e) {
        console.log(e.message)
        return {result: false, message: e.message}
    }
}

export async function logout() {
    await signOut(FireBaseAuth)
}

export async function createUserWithPassword(email, password) {
    try {
        await createUserWithEmailAndPassword(FireBaseAuth, email, password)
        return {result: true, message: ''}
    } catch (e) {
        return {result: false, message: e.message}
    }
}

export async function loginAsGuest() {
    await signInAnonymously(FireBaseAuth)
}

export async function resetPassword(email) {
    await sendPasswordResetEmail(FireBaseAuth, email)
}

export async function login() {
    await router.push('/home')
}

export async function updateUserProfile(userName) {
    await updateProfile(FireBaseAuth.currentUser,
        {displayName: userName, photoURL: ''})
}

export function currentUserId() {
    return FireBaseAuth.currentUser.uid
}

FireBaseAuth.useDeviceLanguage()

FireBaseAuth.onAuthStateChanged(user => {
    if (user) {
        login()
    } else {
        router.push('/')
    }
})


