<template>
  <div>
    <div class="pa-4 pt-8 flex-shrink-0">
      <div class="text-body-2 font-weight-black">
        {{ $t('OrderDetailsAndHistory') }}
      </div>
      <div class="text-h4 mt-4 font-weight-black">
        {{ $t('OrderHistory') }}
      </div>
      <template v-if="loading">
        <div
            style="min-height: 400px"
            class="d-flex align-center justify-center">
          <v-progress-circular indeterminate/>
        </div>
      </template>
      <template v-else>
        <empty-page v-if="orders.length===0"></empty-page>
        <div
            class="mt-8"
            v-else>
          <div class="text-body-2 font-weight-black mb-4">{{ $t('History') }}</div>
          <v-card
              @click="$router.push('/order/'+o.id)"
              outlined
              rounded="lg"
              class="mb-2 pa-4 px-6"
              v-for="o in orders"
              :key="o.id">
            <div class="d-flex">
              <div class="text-body-1 font-weight-black">{{ o.restaurantName }}</div>
              <v-spacer></v-spacer>
              <div class="text-body-2 font-weight-black text-capitalize">
                {{ $t(getOrderStatusByCTSId(o.consumeTypeStatusId ?? 1)) }}
              </div>
            </div>
            <div class="d-flex align-end mt-2">
              <div
                  class="text-caption flex-shrink-1 flex-grow-1 mr-4"
                  style="width: 0">
                <div>{{ o.addressInfo?.date }}/{{ o.addressInfo?.time }}</div>
                <div class="text-no-wrap text-truncate">
                  {{
                    o.addressInfo.deliveryMethod === DeliveryMethod.pickup ?
                        o.addressInfo.firstName + ' ' + o.addressInfo.lastName :
                        o.addressInfo.addressLine1 + '@' + o.addressInfo.firstName + ' ' +
                        o.addressInfo.lastName
                  }}
                </div>

              </div>
              <div class="text-body-1 text-no-wrap text-end">{{ o.totalPrice | priceDisplay }}</div>
            </div>

          </v-card>
        </div>
      </template>

    </div>


  </div>
</template>

<script>
import EmptyPage from "@/pages/widget/EmptyPage";
import {getAllOrdersForUser} from "@/dataLayer/repository/userOrder";
import {DeliveryMethod} from "@/dataLayer/service/OrderService";
import {getOrderStatusByCTSId} from "@/dataLayer/repository/currentOrderState";

export default {
  name: "OrderPage",
  methods: {
    getOrderStatusByCTSId,
  },
  computed: {
    DeliveryMethod() {
      return DeliveryMethod
    },
  },
  components: {EmptyPage},

  data: function () {
    return {
      loading: true,
      currentTab: 0,
      orders: []
    };
  },
  async mounted() {
    this.loading = true
    this.orders = await getAllOrdersForUser()
    this.loading = false
  }
}
</script>

<style scoped>

</style>
