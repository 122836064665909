var render = function render(){
  var _vm$activePost, _vm$activePost2, _vm$activePost$like, _vm$activePost3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "black lighten-4 pb-4 d-flex flex-column",
    staticStyle: {
      "overflow-y": "scroll"
    },
    attrs: {
      "tile": "",
      "height": "calc(100vh - 64px)",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "pa-4 pt-8 flex-shrink-0"
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black"
  }, [_vm._v(" 🌈 " + _vm._s(_vm.$t('WelcomeTo')) + " ")]), _c('div', {
    staticClass: "text-h4 mt-4 font-weight-black d-block overflow-hidden"
  }, [_c('div', [_vm._v(_vm._s(_vm.restaurantInfo.name))])])]), _c('div', {
    staticClass: "mt-2 px-4 flex-shrink-0"
  }, [_c('div', [_c('div', {
    staticClass: "text-caption font-weight-black pink--text text--lighten-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('openingTimes')) + " ")]), _c('div', {
    staticClass: "text-body-2 mt-1 font-weight-black"
  }, [_c('span', {
    staticClass: ""
  }, [_vm._v(" " + _vm._s(_vm.todayOpenTimeDisplay))])])])]), _c('div', {
    staticClass: "mt-2 px-4 flex-shrink-0"
  }, [_c('div', {
    staticClass: "text-body-2"
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "#D0EF27"
    }
  }, [_vm._v("mdi-store ")]), _c('v-icon', {
    attrs: {
      "small": "",
      "color": "amber lighten-4"
    }
  }, [_vm._v("mdi-emoticon ")]), _c('v-icon', {
    attrs: {
      "small": "",
      "color": "pink lighten-4"
    }
  }, [_vm._v("mdi-map-marker ")])], 1), _c('div', {
    staticClass: "text-body-2 mt-1 font-weight-black"
  }, [_c('span', {
    staticClass: ""
  }, [_vm._v(" " + _vm._s(_vm.restaurantInfo.adress1) + " " + _vm._s(_vm.restaurantInfo.postCode) + " " + _vm._s(_vm.restaurantInfo.city) + " ")])])]), _c('div', {
    staticClass: "px-4 mt-4"
  }, [_c('v-card', {
    staticClass: "pa-4 d-flex",
    attrs: {
      "flat": "",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.showAnnoucementDialog = true;
      }
    }
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black text-no-wrap text-truncate flex-grow-1",
    staticStyle: {
      "width": "0px"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-bell ")]), _vm._v(" " + _vm._s(_vm.restaurantInfo.takeawayAnnouncementHead ? _vm.restaurantInfo.takeawayAnnouncementHead : _vm.restaurantInfo.buffetAnnouncementHead) + " ")], 1), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-arrow-right")])], 1)], 1), _c('div', {
    staticClass: "d-flex mx-4 mt-8 align-center"
  }, [_c('div', {
    staticClass: "font-weight-black text-body-2"
  }, [_vm._v(" 💖 " + _vm._s(_vm.$t('SelectYourFavorite')) + " ")])]), _c('div', {
    staticClass: "pa-4 flex-shrink-0",
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "repeat(2,minmax(0,1fr))",
      "grid-gap": "12px"
    }
  }, [_c('v-card', {
    staticClass: "pa-2 px-4 d-flex align-center",
    attrs: {
      "disabled": !_vm.GlobalConfig.delivery,
      "light": _vm.GlobalConfig.delivery,
      "dark": !_vm.GlobalConfig.delivery,
      "color": _vm.GlobalConfig.delivery ? 'pink lighten-5' : 'grey darken-4'
    },
    on: {
      "click": function ($event) {
        return _vm.startToOrder(_vm.DeliveryMethod.delivery);
      }
    }
  }, [_c('v-card', {
    staticClass: "pa-1 mr-2",
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.GlobalConfig.selfPickUp ? 'black' : 'white'
    }
  }, [_vm._v("mdi-truck ")])], 1), _c('div', {
    staticClass: "text-body-2 font-weight-black nippo text-no-wrap text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.DeliveryMethod.delivery)) + " ")])], 1), _c('v-card', {
    staticClass: "pa-2 px-4 d-flex align-center",
    attrs: {
      "disabled": !_vm.GlobalConfig.selfPickUp,
      "dark": !_vm.GlobalConfig.selfPickUp,
      "light": _vm.GlobalConfig.selfPickUp,
      "color": _vm.GlobalConfig.selfPickUp ? 'amber lighten-5' : 'grey darken-4'
    },
    on: {
      "click": function ($event) {
        return _vm.startToOrder(_vm.DeliveryMethod.pickup);
      }
    }
  }, [_c('v-card', {
    staticClass: "pa-1 mr-2",
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.GlobalConfig.selfPickUp ? 'black' : 'white'
    }
  }, [_vm._v("mdi-shopping ")])], 1), _c('div', {
    staticClass: "text-body-2 font-weight-black nippo text-no-wrap text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.DeliveryMethod.pickup)) + " ")])], 1)], 1), _c('div', {
    staticClass: "d-flex mx-4 mt-4 align-center"
  }, [_c('div', {
    staticClass: "font-weight-black text-body-2"
  }, [_vm._v(" 🥳 " + _vm._s(_vm.$t('UpdateFeed')) + " ")]), _c('v-spacer')], 1), _c('div', {
    staticClass: "px-4 pt-4",
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "repeat(auto-fill,minmax(300px,1fr))",
      "grid-gap": "12px"
    }
  }, _vm._l(_vm.postList, function (p) {
    return _c('v-card', {
      key: p.id,
      staticClass: "d-flex align-start py-4 px-4",
      attrs: {
        "color": "surface"
      },
      on: {
        "click": function ($event) {
          return _vm.showPostDetail(p.id);
        }
      }
    }, [_c('div', {
      staticClass: "flex-grow-1 mr-4 d-flex flex-column",
      staticStyle: {
        "width": "0",
        "height": "100%"
      }
    }, [_c('div', {
      staticClass: "text-body-1 font-weight-black text"
    }, [_vm._v(" " + _vm._s(p.content) + " ")]), _c('v-spacer'), _c('div', {
      staticClass: "text-caption d-flex align-center mt-1"
    }, [_c('v-card', {
      staticClass: "px-2 d-flex align-center grey darken-3",
      attrs: {
        "elevation": "0"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.likePost(p.id);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "color": "pink",
        "small": ""
      }
    }, [_vm._v("mdi-heart ")]), _vm._v(" " + _vm._s(p.like) + " ")], 1), _c('v-icon', [_vm._v("mdi-circle-small")]), _vm._v(" " + _vm._s(_vm._f("timeToNow")(p.createdAt)) + " ")], 1)], 1), _c('v-card', {
      attrs: {
        "rounded": "lg"
      }
    }, [_c('v-img', {
      attrs: {
        "height": "72",
        "width": "108",
        "src": p.imageRealUrl
      }
    })], 1)], 1);
  }), 1), _c('v-bottom-sheet', {
    model: {
      value: _vm.showPostDialog,
      callback: function ($$v) {
        _vm.showPostDialog = $$v;
      },
      expression: "showPostDialog"
    }
  }, [_vm.activePost ? _c('v-card', {
    attrs: {
      "tile": "",
      "color": "grey lighten-4",
      "width": "100vw"
    }
  }, [_c('div', {
    staticClass: "pa-6"
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.restaurantInfo.name))]), _c('div', {
    staticClass: "text-body-2 mt-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('Published')) + ": " + _vm._s(_vm._f("timeToNow")(_vm.activePost.createdAt)) + " ")])]), _c('div', {
    staticClass: "mb-16",
    staticStyle: {
      "max-height": "calc(90vh - 200px)",
      "overflow-y": "scroll"
    }
  }, [(_vm$activePost = _vm.activePost) !== null && _vm$activePost !== void 0 && _vm$activePost.imageRealUrl ? _c('v-card', {
    staticClass: "pa-6",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "600",
      "src": (_vm$activePost2 = _vm.activePost) === null || _vm$activePost2 === void 0 ? void 0 : _vm$activePost2.imageRealUrl,
      "width": "100%"
    }
  })], 1) : _vm._e(), _c('v-card', {
    staticClass: "px-6 pb-6 pt-6 pb-16",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.activePost.content) + " ")])], 1), _c('v-card', {
    staticClass: "pa-4 px-6 d-flex text-body-1 font-weight-black",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0",
      "right": "0"
    },
    attrs: {
      "tile": "",
      "color": "grey lighten-4"
    }
  }, [_c('div', {
    staticClass: "d-flex mr-4 align-center"
  }, [_c('v-card', {
    staticClass: "pa-2 d-flex mr-2 align-center",
    attrs: {
      "flat": "",
      "rounded": "xl",
      "color": "pink lighten-5"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.likePost(_vm.activePostId);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "pink"
    }
  }, [_vm._v("mdi-heart ")])], 1), _vm._v(" " + _vm._s((_vm$activePost$like = (_vm$activePost3 = _vm.activePost) === null || _vm$activePost3 === void 0 ? void 0 : _vm$activePost3.like) !== null && _vm$activePost$like !== void 0 ? _vm$activePost$like : 0) + " ")], 1), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.showPostDialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1), _c('v-bottom-sheet', {
    model: {
      value: _vm.showAnnoucementDialog,
      callback: function ($$v) {
        _vm.showAnnoucementDialog = $$v;
      },
      expression: "showAnnoucementDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "color": "grey lighten-4",
      "width": "100vw"
    }
  }, [_c('div', {
    staticClass: "pa-6"
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.restaurantInfo.name))])]), _c('div', {
    staticClass: "mb-16",
    staticStyle: {
      "max-height": "calc(90vh - 206px)",
      "overflow-y": "scroll"
    }
  }, [_c('v-card', {
    staticClass: "px-6 pt-6 pb-16 text-body-2",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.restaurantInfo.takeawayAnnouncementHead ? _vm.restaurantInfo.takeawayAnnouncementHead : _vm.restaurantInfo.buffetAnnouncementHead) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.restaurantInfo.takeawayAnnouncementBody ? _vm.restaurantInfo.takeawayAnnouncementBody : _vm.restaurantInfo.buffetAnnouncementBody) + " ")])])], 1), _c('v-card', {
    staticClass: "pa-4 d-flex align-center justify-center text-body-1 font-weight-black",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0",
      "right": "0",
      "height": "64px"
    },
    attrs: {
      "tile": "",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        _vm.showAnnoucementDialog = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-check ")]), _vm._v(" " + _vm._s(_vm.$t('OK')) + " ")], 1)], 1)], 1), _c('address-selector', {
    on: {
      "save": _vm.save
    },
    model: {
      value: _vm.showAddressDialog,
      callback: function ($$v) {
        _vm.showAddressDialog = $$v;
      },
      expression: "showAddressDialog"
    }
  }), _c('restaurant-detail-page', {
    attrs: {
      "show": _vm.showRestaurantInfo,
      "opening-time-list": _vm.openingTimeList,
      "is-open": _vm.isOpen,
      "today-open-time-display": _vm.todayOpenTimeDisplay,
      "restaurant-info": _vm.restaurantInfo
    },
    on: {
      "close": function ($event) {
        _vm.showRestaurantInfo = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }