<template>
  <div
      style="width: 100%;"
      class="d-flex flex-column justify-center align-center text-center fullScreenHeight"
  >
    <div
        style="width: 200px"
        class="d-flex justify-center"
    >
      <lottie-animation
          loop
          :animation-data="require('@/assets/loading.json')"
      ></lottie-animation>
    </div>
    <div class="pa-4">
      <div class="text-h5 font-weight-black">{{ $t('Loading') }}</div>
      <div class="text-body-1 mt-2">{{ $t('OrderArrived') }}</div>

    </div>
    <div
        style="position: absolute;bottom: 72px"
        class="text-body-2 text--secondary"
    ><span class="font-weight-black">Aaden POS</span>
      designed with ❤️
    </div>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCategoryWithDishes} from "@/dataLayer/service/api";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"; // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "LoadingPage",
  async mounted() {

    await getCategoryWithDishes('DE')

    const goNormal = async () => {
      await this.$router.push('/login')
    }
    if (GlobalConfig.SaaSVersion) {
      try {
        await goNormal()
      } catch (e) {
        await goNormal()
      }


    } else {

      await goNormal()
    }
  }
}
</script>

<style scoped>

</style>
