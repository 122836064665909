<script>
export default {
  name: "CurrentAddressDisplay",
  props: {
    addressInfo: {},
    isPickUp: {}
  },
  computed: {
    haveAddressInfo() {
      return this.addressInfo?.firstName
    }
  }
}
</script>

<template>
  <div
      @click="$emit('click')"
      class="d-flex align-center">
    <div>
      <div class="text-caption font-weight-black">
        {{ isPickUp ? $t('PickupBy') : $t('DeliveryTo') }}
      </div>
      <div
          class="text-body-1 font-weight-black"
          v-if="haveAddressInfo">
        <template>
          {{ addressInfo.firstName + ' ' + addressInfo.lastName }}<br>
        </template>
        <div
            class="text-body-2 font-weight-bold"
            v-if="!isPickUp">
          {{
            addressInfo.addressLine1 + ' ' + addressInfo.plz + ' '
            + addressInfo.city

          }}
        </div>

      </div>
      <div
          v-else
          class="d-flex align-center text-body-1 font-weight-black"
      >
        <v-icon small left color="black">mdi-alert-circle</v-icon>
        {{ isPickUp ? $t('MissingPickupInformation') : $t('MissingDeliveryInformation') }}
      </div>
    </div>
    <v-spacer></v-spacer>
    <v-icon>mdi-arrow-right</v-icon>
  </div>
</template>

<style scoped>

</style>
