var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-center fullScreenHeight"
  }, [_c('v-fade-transition', [_vm.show ? _c('div', {
    staticClass: "pa-4 text-center"
  }, [_c('v-icon', {
    attrs: {
      "center": "",
      "size": "100"
    }
  }, [_vm._v(" mdi-check-all ")]), _c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('OrderSubmitted')))]), _c('div', {
    staticClass: "text-body-2 mt-4"
  }, [_vm._v(_vm._s(_vm.$t('YourOrderBeenReceived')) + " ")]), _c('v-btn', {
    staticClass: "font-weight-black text-body-1",
    staticStyle: {
      "position": "fixed",
      "bottom": "0",
      "left": "0"
    },
    attrs: {
      "block": "",
      "x-large": "",
      "elevation": "0",
      "height": "64",
      "color": "grey lighten-4"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Finish')) + " ")])], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }