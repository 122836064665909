import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";
import {db} from "@/plugins/fire-base";


const orderPath = 'order'

export async function getActiveProductCode () {
    const orders = await getCurrentStoreSubscriptions()
    const activeOrder = []
    for (const order of orders) {
        const sub = order.sub
        if (sub.status === 'active') {
            activeOrder.push(order.order)
        }
    }
    return activeOrder.map(it => it.productList.filter(that => that.metadata.type === 'software')
        .map(that => that.metadata.code)).flat()
}

export async function getCurrentStoreSubscriptions () {
    return await getSubscriptionsByStore(GlobalConfig.deviceId)
}

export async function getSubscriptionsByStore (storeId) {
    const result = []
    const orders = await getStoreOrders(storeId)
    for (const order of orders) {
        const sub = await getUserSubscriptionDetail(order.userId, order.subscriptionId)
        result.push({
            order,
            sub,
        })
    }
    return result
}

export async function getUserSubscriptionDetail (userId, subId) {
    return (await getDoc(doc(db, 'customers', userId, 'subscriptions', subId))).data()
}

export async function getStoreOrders (id) {
    const collectionRef = collection(db, orderPath)
    const resString = query(collectionRef, where('storeId', '==', id.toString()))
    const resNumber = query(collectionRef, where('storeId', '==', parseInt(id)))
    const realStringList = await resultOfId(resString)
    const realNumberList = await resultOfId(resNumber)
    const res = realStringList.concat(realNumberList)
    return res
}

export async function resultOfId (query, logLabel = '') {
    const res = (await getDocs(query)).docs
        .map(contentOfId)
    if (logLabel) {
        console.log(res, logLabel)
    }
    return res
}

export function contentOfId (doc) {
    return {
        ...doc.data(),
        id: doc.id,
    }
}
