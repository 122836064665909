import {currentUserId, db} from "@/plugins/fire-base";
import {collection, doc, query, setDoc, where} from "firebase/firestore";
import {executeQuery} from "@/dataLayer/service/utils";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";
import {getCurrentRestaurantInfo} from "@/dataLayer/repository/restaurantInfo";
import {orderBy} from "lodash-es";

const path = 'takeaway-user-order'

export async function setOrderForUser(orderInfo) {
    orderInfo.userId = currentUserId()
    orderInfo.dataTerminalId = GlobalConfig.dataTerminalId
    orderInfo.deviceId = GlobalConfig.deviceId
    orderInfo.restaurantName = (await getCurrentRestaurantInfo()).name
    const id = orderInfo.id.toString()
    try {
        await setDoc(doc(db, path, id), orderInfo)
        return true
    } catch (e) {
        console.log(e)
        return false
    }

}


export async function getAllOrdersForUser() {
    return orderBy(await executeQuery(query(collection(db, path),
        where('userId', '==', currentUserId()))),
        (order) => [order.addressInfo.date + order?.addressInfo.time],
        ['desc'])
}
