var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column justify-center",
    staticStyle: {
      "min-height": "100vh"
    }
  }, [_c('div', {
    staticClass: "pa-6 mt-n16"
  }, [!_vm.error ? [_c('div', {
    staticClass: "text-body-1 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('WelcomeTo')) + " ")]), _c('div', {
    staticClass: "text-h3 mt-4 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.restaurantInfo.name) + " ")])] : [_c('div', {
    staticClass: "text-body-1 font-weight-black error--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('AnErrorAppeared')) + " ")]), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v(_vm._s(_vm.errorMessage))])], _vm.emailChecked ? [_c('v-card', {
    staticClass: "pa-4 mt-8 text-body-1 font-weight-black d-flex",
    attrs: {
      "tile": "",
      "color": "grey lighten-4 black--text",
      "outlined": ""
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Email')) + ": " + _vm._s(_vm.email) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-close")])], 1)] : [_c('div', {
    staticClass: "text-body-2 mt-8"
  }, [_vm._v(" " + _vm._s(_vm.$t('EnterEmail')) + " ")]), _c('div', [_c('v-text-field', {
    staticClass: "mt-1",
    staticStyle: {
      "border-radius": "0 !important"
    },
    attrs: {
      "rules": _vm.emailRules,
      "clearable": "",
      "disabled": _vm.emailChecked,
      "outlined": "",
      "hide-details": "",
      "placeholder": _vm.$t('EnterEmailPlaceholder')
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1)], _vm.emailChecked ? [_c('div', [_c('v-text-field', {
    staticClass: "mt-4",
    staticStyle: {
      "border-radius": "0 !important"
    },
    attrs: {
      "placeholder": _vm.$t('EnterPassword'),
      "hide-details": "",
      "append-icon": _vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassword ? 'text' : 'password',
      "outlined": ""
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1)] : _vm._e(), _c('v-card', {
    staticClass: "pa-4 mt-4 text-body-1 font-weight-black",
    attrs: {
      "tile": "",
      "disabled": _vm.loading,
      "color": "black white--text",
      "outlined": ""
    },
    on: {
      "click": _vm.startToLogin
    }
  }, [_vm._v(" " + _vm._s(_vm.emailChecked ? _vm.userExist ? _vm.$t('Login') : _vm.$t('SignUp') : _vm.$t('LoginSignUp')) + " ")]), _vm.emailChecked ? _c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "tile": "",
      "disabled": _vm.loading,
      "color": "grey lighten-5 text-body-1 font-weight-black",
      "flat": ""
    },
    on: {
      "click": function ($event) {
        _vm.showResetPasswordDialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ForgotPassword')) + " ")]) : _vm._e(), !_vm.email ? _c('v-card', {
    staticClass: "pa-4",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "tile": "",
      "disabled": _vm.loading,
      "color": "grey lighten-5 text-body-1 font-weight-black",
      "flat": ""
    },
    on: {
      "click": _vm.loginAsGuest
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ContinueAsGuest')) + " ")]) : _vm._e()], 2), _c('div', {
    staticClass: "text-caption mt-2 text--secondary",
    staticStyle: {
      "position": "fixed",
      "bottom": "48px",
      "left": "24px",
      "z-index": "0"
    },
    on: {
      "click": function ($event) {
        _vm.confirmedAGB = !_vm.confirmedAGB;
      }
    }
  }, [_c('div', [_c('v-icon', {
    staticStyle: {
      "margin-top": "-2px"
    },
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.confirmedAGB ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline') + " ")]), _vm._v(" " + _vm._s(_vm.$t('AcceptAGB', {
    info: _vm.restaurantInfo.name
  })) + " ")], 1), _c('div', {
    staticClass: "mt-2 font-weight-black"
  }, [_vm._v(" " + _vm._s(_vm.$t('AadenPosShops'))), _c('span', {
    staticClass: "ml-1 black white--text px-1"
  }, [_vm._v("Aaden POS")])])])]), _c('v-bottom-sheet', {
    model: {
      value: _vm.showConfirmAGBDialog,
      callback: function ($$v) {
        _vm.showConfirmAGBDialog = $$v;
      },
      expression: "showConfirmAGBDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "dark": "",
      "color": "black"
    }
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('Attention')))]), _c('div', {
    staticClass: "text-body-1 my-4"
  }, [_vm._v(_vm._s(_vm.$t('ConfirmAGBFirst')))]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "text-body-2 text-capitalize font-weight-black",
    attrs: {
      "elevation": "0",
      "large": "",
      "width": "50%",
      "color": "black white--text",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showConfirmAGBDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Back')) + " ")]), _c('v-btn', {
    staticClass: "text-body-2 text-capitalize font-weight-black",
    staticStyle: {
      "text-transform": "none !important"
    },
    attrs: {
      "large": "",
      "loading": _vm.loading,
      "elevation": "0",
      "width": "50%",
      "color": "white black--text",
      "tile": ""
    },
    on: {
      "click": _vm.confirmAGB
    }
  }, [_vm._v(_vm._s(_vm.$t('AGBConfirmBtn')) + " ")])], 1)])], 1), _c('v-bottom-sheet', {
    model: {
      value: _vm.showResetPasswordDialog,
      callback: function ($$v) {
        _vm.showResetPasswordDialog = $$v;
      },
      expression: "showResetPasswordDialog"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "text-h4 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('ForgottenPasswordTitle')))]), _c('div', {
    staticClass: "text-body-1 my-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('ForgottenPasswordHint')) + " ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    attrs: {
      "elevation": "0",
      "large": "",
      "width": "50%",
      "color": "black white--text",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showResetPasswordDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Back')) + " ")]), _c('v-btn', {
    attrs: {
      "large": "",
      "loading": _vm.loading,
      "elevation": "0",
      "width": "50%",
      "color": "white black--text",
      "tile": ""
    },
    on: {
      "click": _vm.confirmResetEmail
    }
  }, [_vm._v(_vm._s(_vm.$t('ResetPassword')) + " ")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }