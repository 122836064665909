var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('empty-page', {
    attrs: {
      "title": '该网页不存在',
      "tips": '或者输入正确的网站域名,或者检查您的网络设置!'
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }