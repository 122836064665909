var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column justify-center align-center text-center fullScreenHeight",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('lottie-animation', {
    attrs: {
      "loop": "",
      "animation-data": require('@/assets/loading.json')
    }
  })], 1), _c('div', {
    staticClass: "pa-4"
  }, [_c('div', {
    staticClass: "text-h5 font-weight-black"
  }, [_vm._v(_vm._s(_vm.$t('Loading')))]), _c('div', {
    staticClass: "text-body-1 mt-2"
  }, [_vm._v(_vm._s(_vm.$t('OrderArrived')))])]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-body-2 text--secondary",
    staticStyle: {
      "position": "absolute",
      "bottom": "72px"
    }
  }, [_c('span', {
    staticClass: "font-weight-black"
  }, [_vm._v("Aaden POS")]), _vm._v(" designed with ❤️ ")]);

}]

export { render, staticRenderFns }