var render = function render(){
  var _vm$dish$code;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-2",
    on: {
      "click": function ($event) {
        _vm.isActive ? _vm.$emit('click') : null;
      }
    }
  }, [_c('v-card', {
    staticClass: "mx-4 pa-4 d-flex",
    staticStyle: {
      "border-radius": "12px",
      "position": "relative"
    },
    attrs: {
      "disabled": !_vm.isActive,
      "outlined": "",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "flex-grow-1 overflow-hidden d-flex flex-column",
    staticStyle: {
      "border-radius": "0"
    }
  }, [_c('div', {
    staticClass: "text-body-2 font-weight-black text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$dish$code = _vm.dish.code) !== null && _vm$dish$code !== void 0 ? _vm$dish$code : '') + "." + _vm._s(_vm.dish.dishName) + " ")]), _c('div', {
    staticClass: "text-body-2 my-2 text-no-wrap text-truncate",
    domProps: {
      "innerHTML": _vm._s(_vm.dish.dishDesc ? _vm.dish.dishDesc : 'Keine Beschreibung')
    }
  }), _c('v-spacer'), _c('div', {
    staticClass: "text-body-1 font-weight-black d-flex align-center",
    staticStyle: {
      "width": "fit-content"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('detail', _vm.dish);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm._f("priceDisplay")(_vm.dish.price)) + " "), _c('v-icon', {
    attrs: {
      "small": "",
      "right": ""
    }
  }, [_vm._v("mdi-information-outline ")])], 1)], 1), _vm.haveImage ? _c('v-card', {
    staticClass: "dishImage",
    staticStyle: {
      "border-radius": "8px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-img', {
    attrs: {
      "height": "80",
      "width": "80",
      "contain": "",
      "src": _vm.dish.cloudImage
    }
  })], 1) : _vm._e(), _vm.count > 0 || !_vm.isActive ? _c('v-card', {
    staticClass: "pa-1 px-3",
    staticStyle: {
      "border-radius": "50rem",
      "position": "absolute",
      "bottom": "8px",
      "right": "8px"
    },
    attrs: {
      "flat": "",
      "color": "grey lighten-3"
    }
  }, [!_vm.isActive ? [_c('v-icon', [_vm._v("mdi-package-variant-remove")])] : [_vm._v(" " + _vm._s(_vm.count) + " ")]], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }