import {collection, deleteDoc, doc, getDocs, query, setDoc, where} from 'firebase/firestore'
import {currentUserId, db} from "@/plugins/fire-base";
import {calculateDeliveryRule} from "@/dataLayer/service/api";
import {DeliveryMethod} from "@/dataLayer/service/OrderService";
import IKUtils from "innerken-js-utils";
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager";
import {OrderInfo} from "@/dataLayer/repository/currentOrderState";

export class ContactAddress {
    constructor(info) {
        this.firstName = info.firstName
        this.lastName = info.lastName
        this.addressLine1 = info.addressLine1
        this.addressLine2 = info.addressLine2
        this.email = info.email
        this.tel = info.tel
        this.longitude = info.longitude
        this.latitude = info.latitude
        this.plz = info.plz
        this.isDefault = info.isDefault ?? false
        this.type = info.type
        this.city = info.city
    }

    hash() {
        return Object.values(this).reduce((str, i) => {
            return str + i
        }, "")
    }
}

/**
 * @param {{[p: string]: T}} obj
 * @return string
 */
function hash(obj) {
    return Object.values(obj).reduce((str, i) => {
        return str + i
    }, "")
}


/**
 *
 * @returns {Promise<ContactAddress[]>}
 */
export async function getCurrentAddressList(type) {
    const list = (await getAddressList(type))
    if (type !== DeliveryMethod.pickup) {
        for (const a of list) {
            a.deliveryRule = await calculateDeliveryRule(a)
        }
    }
    return list
}

export async function getUsableAddressList(type) {
    const list = (await getAddressList(type))
    if (type !== DeliveryMethod.pickup) {
        for (const a of list) {
            a.deliveryRule = await calculateDeliveryRule(a)
        }
    }
    return list.filter(it => type === DeliveryMethod.pickup || it.deliveryRule.canDelivery)
}

export async function resetAddressToAnyUsable(deliveryMethod) {
    const savedAddress = await getUsableAddressList(deliveryMethod)
    if (savedAddress.length !== 0) {
        OrderInfo.address = savedAddress.find(it => it.isDefault) ?? savedAddress[0]
        if (deliveryMethod === DeliveryMethod.delivery) {
            OrderInfo.deliveryRule = savedAddress[0].deliveryRule
        }
    }
}

/**
 *
 * @param {{}} address
 * @param type
 * @returns {Promise<string>}
 */
export async function saveAddress(address, type) {
    return await setAddressForUser(address, type)
}

export async function deleteAddress(id, type) {
    await deleteAddressForUser(id, type)
    return await getCurrentAddressList(type)
}


const path = 'takeaway-user-preference'

export async function updateUserPreference(defaultDeliveryMethod) {
    await setDoc(doc(db, path, currentUserId()), {defaultDeliveryMethod})
}

const addressPath = 'takeaway-user-address'

async function setAddressForUser(address, type) {
    const tmp = IKUtils.deepCopy(address)
    tmp.userId = currentUserId()
    tmp.type = type
    delete tmp.deliveryRule
    const id = hash(tmp)
    await setDoc(doc(db, addressPath, id), JSON.parse(JSON.stringify(tmp)))
    return id
}

export function deliveryMethodOk(deliveryMethod) {
    return deliveryMethod === DeliveryMethod.pickup ? GlobalConfig.selfPickUp : GlobalConfig.delivery
}

export function firstAvailableDeliveryMethod() {
    return Object.values(DeliveryMethod).find(deliveryMethodOk)
}

async function deleteAddressForUser(id) {

    try {
        await deleteDoc(doc(db, addressPath, id))
    } catch (e) {
        console.log(e, 'err')
    }

}


async function getAddressList(type) {
    return (await getDocs(query(collection(db, addressPath), where('userId', '==', currentUserId()),
        where('type', '==', type)))).docs.map(it => {
        return {
            ...it.data(),
            id: it.id
        }
    })
}

export function getContactInfoFromAddress(address) {
    return ['firstName', 'lastName', 'email', 'tel', 'addressLine2']
        .reduce((obj, i) => {
            obj[i] = address?.[i] ?? ''
            return obj
        }, {})
}

export function getHashFieldForAddress(address) {
    return ['firstName', 'lastName', 'email', 'tel',
        'addressLine2', 'addressLine1', 'city', 'plz']
        .reduce((str, i) => {
            str = str + address?.[i] ?? ''
            return str
        }, '')
}


