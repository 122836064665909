<template>
  <v-bottom-sheet
      v-model="realValue"
      fullscreen
      scrollable
      dark
  >
    <v-card
        tile
        width="100vw"
    >
      <div
          style="overflow-y: scroll"
          class="fullScreenHeight pb-16"
      >
        <div
            class="pa-6 px-4"
        >
          <div class="d-flex align-center">
            <div class="text-h5 font-weight-black">{{ !isPickUp ? $t('AddressData') : $t('ContactData') }}</div>
            <v-spacer/>
            <v-btn
                icon
                large
                @click="realValue=false"
                class="ml-n2"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-form
              ref="form"
              v-model="addressIsValid"
          >
            <div class="mt-12">
              <div class="mt-4">
                <div style="display: grid;grid-template-columns: repeat(2,1fr);grid-gap: 12px">
                  <div>
                    <div class="text-body-2 font-weight-black">{{ $t('firstName') }}</div>
                    <v-text-field
                        dense
                        :rules="[rules.required]"
                        v-model="addressInfo.firstName"
                        name="firstName"
                        class="mt-1"
                        outlined
                    />
                  </div>
                  <div>
                    <div class="text-body-2 font-weight-black">{{ $t('lastName') }}</div>
                    <v-text-field
                        dense
                        :rules="[rules.required]"
                        v-model="addressInfo.lastName"
                        name="lastName"
                        class="mt-1"
                        outlined
                    />
                  </div>
                </div>
                <template v-if="!isPickUp">
                  <div class="text-caption font-weight-black">{{ $t('Address') }}</div>
                  <v-text-field
                      dense
                      v-model="addressInfo.addressLine1"
                      :rules="[rules.required,rules.address]"
                      :placeholder="$t('AddressPlaceholder')"
                      name="address-line1"
                      class="mt-1"
                      outlined
                  />
                  <div class="text-body-2 font-weight-black">{{ $t('AdditionalAddress') }}</div>
                  <v-text-field
                      dense
                      v-model="addressInfo.addressLine2"
                      :placeholder="$t('ExtraDeliveryNotes')"
                      name="address-line2"
                      class="mt-1"
                      outlined
                  />
                  <div style="display: grid;grid-template-columns: repeat(2,minmax(0,1fr));grid-gap: 12px">
                    <div>
                      <div class="text-body-2 font-weight-black">{{ $t('City') }}</div>
                      <v-text-field
                          v-model="addressInfo.city"
                          dense
                          :rules="[rules.required]"
                          name="city"
                          class="mt-1"
                          outlined
                      />
                    </div>
                    <div>
                      <div class="text-body-2 font-weight-black">{{ $t('ZipCode') }}</div>
                      <v-text-field
                          v-model="addressInfo.plz"
                          dense
                          :rules="[rules.required]"
                          name="postal-code"
                          class="mt-1"
                          outlined
                      />
                    </div>
                  </div>
                </template>

                <div class="text-body-2 font-weight-black">{{ $t('phone') }}</div>
                <v-text-field
                    dense
                    :rules="[rules.required]"
                    v-model="addressInfo.tel"
                    name="phone"
                    class="mt-1"
                    outlined
                />
                <div class="text-body-2 font-weight-black">{{ $t('Email') }}</div>
                <v-text-field
                    dense
                    :rules="[rules.required,rules.email]"
                    v-model="addressInfo.email"
                    name="email"
                    class="mt-1"
                    outlined
                />
                <div class="d-flex align-center">
                  <div class="text-body-2 font-weight-black">{{ $t('Default address') }}</div>
                  <v-spacer></v-spacer>
                  <v-switch
                      color="amber"
                      hide-details
                      class="mt-0"
                      v-model="addressInfo.isDefault"
                  ></v-switch>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div
            style="position: fixed;bottom: 0;left: 0;right: 0;"
            v-if="addressIsValid"
        >
          <v-btn
              tile
              :disabled="!addressIsValid"
              @click="saveAddress"
              elevation="0"
              block
              x-large
              class="mt-4 text-body-1 font-weight-black"
              color="white black--text"
          >
                <span class="text-capitalize">{{
                    $t('Save')
                  }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-bottom-sheet
        dark
        v-model="showCorrectedAddress"
    >
      <v-card
          class="pa-4"
          v-if="correctedAddress"
      >
        <div class="text-h4">
          👀
        </div>
        <div class="text-body-1 font-weight-black" v-if="!showErrorMapTips">{{ $t('checkAddress') }}</div>
        <div class="d-flex text-body-1 font-weight-black mt-2" v-else>
          <v-icon color="red" class="mr-4">mdi-alert</v-icon>
          {{ $t('sureToUseAddress') }}
        </div>
        <div class="text-body-1 font-weight-black mt-4">
          <div>
            {{ correctedAddress.addressLine1 }}
          </div>
          <div>
            {{ correctedAddress.plz }} {{ correctedAddress.city }}
          </div>
        </div>
        <v-btn
            class="mt-4 text-body-1 font-weight-black"
            large
            @click="confirmCorrectedAddress"
            block
            light
            color="white"
        >
          {{ $t('OK') }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
    <v-bottom-sheet
        dark
        v-model="showError"
    >
      <v-card
          class="pa-4"
      >

        <div class="text-body-1 font-weight-black">{{ $t('❌您输入的地址信息有误') }}</div>
        <div class="text-body-2 mt-4">
          {{$t('cantFindAddressInMaps')}}
        </div>
        <v-btn
            class="mt-4 text-body-1 font-weight-black"
            large
            @click="reset"
            block
            light
            color="white"
        >
          {{ $t('OK') }}
        </v-btn>
      </v-card>
    </v-bottom-sheet>
  </v-bottom-sheet>

</template>

<script>
import {DeliveryMethod} from '@/dataLayer/service/OrderService'
import {ContactAddress, getContactInfoFromAddress, getCurrentAddressList} from '@/dataLayer/repository/localAddressInfo'
import {getDetailData, getPredictions} from "@/dataLayer/service/GoogleMapService";
import {addPlugin} from "vue-cli-plugin-vuetify/generator/tools/fonts";
import IKUtils from "innerken-js-utils";
import i18n from "@/i18n";

const defaultAddressData = {
  tel: '',
  email: '',
  note: '',
  firstName: '',
  lastName: '',
  addressLine2: '',
  addressLine1: '',
  city: '',
  plz: '',
  isDefault: false,
}
let resolve = null
export default {
  name: "AddNewAddressDialog",
  props: {
    value: {},
    type: {},
    oldId: {},
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.realValue = val
        if (val) {
          this.reset()
        }
      }
    },
    realValue(val, old) {
      if (val !== old) {
        this.$emit('input', val)
      }
    },
  },
  computed: {
    isPickUp() {
      return this.type === DeliveryMethod.pickup
    }
  },
  data: function () {
    return {
      showCorrectedAddress: null,
      showError: null,
      addressIsValid: null,
      realValue: null,
      addressInfo: IKUtils.deepCopy(defaultAddressData),

      correctedAddress: null,
      rules: {
        required: v => !!v || i18n.t('RequiredField'),
        email: v => /.+@.+/.test(v) || i18n.t('EnterValidEmail'),
        address: v => /^.*[0-9]{1,3}/.test(v) || this.$t('StreetNameFollowedStreetNumber'),
      },
      showErrorMapTips: false
    }
  },
  methods: {
    addPlugin,
    getAddressWithOutSpace (address) {
      return address.replace(/\s+/g, '')
    },
    async saveAddress() {
      try {
        const addressData = {
          addressLine1: '',
          addressLine2: '',
          city: '',
          plz: '',
          longitude: '',
          latitude: ''
        }
        if (!this.isPickUp) {
          const prediction = await getPredictions(
              this.addressInfo.addressLine1 + ',' + this.addressInfo.city + ' ' + this.addressInfo.plz)
          if (prediction.length > 0) {
            this.showErrorMapTips = false
            const detail = await getDetailData(prediction[0].place_id)
            if (this.getAddressWithOutSpace(detail.addressLine1) !== this.addressInfo.addressLine1 && detail.addressLine1 !== this.addressInfo.addressLine1) {
              this.showErrorMapTips = true
              detail.addressLine1 = this.addressInfo.addressLine1
              detail.titleAddress = detail.addressLine1 + ', ' + detail.plz + ' ' + detail.city + ', ' + detail.country
            }
            await new Promise(res => {
              resolve = res
              this.correctedAddress = detail
              this.showCorrectedAddress = true
            })
            const data = new ContactAddress(Object.assign({}, detail, {
              ...getContactInfoFromAddress(this.addressInfo),
              isDefault: this.addressInfo.isDefault,
              type: this.type
            }))
            this.$emit('address-save', data)
          }
        } else {
          const data = new ContactAddress(Object.assign({}, addressData, {
            ...getContactInfoFromAddress(this.addressInfo),
            isDefault: this.addressInfo.isDefault,
            type: this.type
          }))
          this.$emit('address-save', data)
        }

      } catch (e) {
        console.log(e)
        this.showError = true
      }

    },
    async confirmCorrectedAddress() {
      if (resolve) {
        resolve(true)
      }
    },
    async reset() {
      this.showError = false
      if (this.oldId) {
        this.addressInfo = (await getCurrentAddressList(this.type)).find(it => it.id === this.oldId)
      } else {
        this.addressInfo = IKUtils.deepCopy(defaultAddressData)
      }
    },
  }
}
</script>

<style scoped>

</style>
